import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import { Configuration} from '../configuration'

import { AcceptanceDeadline } from '../models/AcceptanceDeadline';
import { AppVersionResponse } from '../models/AppVersionResponse';
import { Booking } from '../models/Booking';
import { BookingForForwarderTO } from '../models/BookingForForwarderTO';
import { BookingRequest } from '../models/BookingRequest';
import { BookingsFilterRequestTO } from '../models/BookingsFilterRequestTO';
import { ColorBorder } from '../models/ColorBorder';
import { ConfirmBookingEntries } from '../models/ConfirmBookingEntries';
import { ConstantForwarderStatisticFloatValueResponseTO } from '../models/ConstantForwarderStatisticFloatValueResponseTO';
import { ConstantForwarderStatisticValueRequestTO } from '../models/ConstantForwarderStatisticValueRequestTO';
import { Container } from '../models/Container';
import { ContainerBookingCreationRequestTO } from '../models/ContainerBookingCreationRequestTO';
import { CreateForwarderUserTO } from '../models/CreateForwarderUserTO';
import { CreateTerminalOperatorUserTO } from '../models/CreateTerminalOperatorUserTO';
import { CreateTrainOperatorUserTO } from '../models/CreateTrainOperatorUserTO';
import { CustomerTO } from '../models/CustomerTO';
import { DangerousGoodsData } from '../models/DangerousGoodsData';
import { DeliveryTimes } from '../models/DeliveryTimes';
import { DepotContainerBookingTO } from '../models/DepotContainerBookingTO';
import { DepotGetStatusMultiRequestTO } from '../models/DepotGetStatusMultiRequestTO';
import { DepotGetStatusMultiResponseTO } from '../models/DepotGetStatusMultiResponseTO';
import { DepotGetStatusRequestTO } from '../models/DepotGetStatusRequestTO';
import { DepotGetStatusResponseTO } from '../models/DepotGetStatusResponseTO';
import { ErrorResponse } from '../models/ErrorResponse';
import { Forwarder } from '../models/Forwarder';
import { ForwarderApproval } from '../models/ForwarderApproval';
import { ForwarderUserTO } from '../models/ForwarderUserTO';
import { GetStatusDownloadCSVRequestTO } from '../models/GetStatusDownloadCSVRequestTO';
import { GetStatusRequest } from '../models/GetStatusRequest';
import { GetStatusRequestResponseTO } from '../models/GetStatusRequestResponseTO';
import { GetStatusResponse } from '../models/GetStatusResponse';
import { IspsChangeApprovalRequestTO } from '../models/IspsChangeApprovalRequestTO';
import { IspsLicenseKeyResponseTO } from '../models/IspsLicenseKeyResponseTO';
import { IspsLicenseTO } from '../models/IspsLicenseTO';
import { IspsTruckerChangeRequestTO } from '../models/IspsTruckerChangeRequestTO';
import { IspsTruckerChangeTO } from '../models/IspsTruckerChangeTO';
import { IspsTruckerTO } from '../models/IspsTruckerTO';
import { LocalTime } from '../models/LocalTime';
import { OneToOneRestriction } from '../models/OneToOneRestriction';
import { ParseGetStatusFileResponse } from '../models/ParseGetStatusFileResponse';
import { PickupTimes } from '../models/PickupTimes';
import { RangedTerminalStatisticsValueRequest } from '../models/RangedTerminalStatisticsValueRequest';
import { RangedTerminalStatisticsValueResponse } from '../models/RangedTerminalStatisticsValueResponse';
import { ResetPassword } from '../models/ResetPassword';
import { SealData } from '../models/SealData';
import { Terminal } from '../models/Terminal';
import { TerminalModule } from '../models/TerminalModule';
import { TerminalOpeningHour } from '../models/TerminalOpeningHour';
import { TerminalOperatorUserTO } from '../models/TerminalOperatorUserTO';
import { TerminalReduced } from '../models/TerminalReduced';
import { TimeSeriesRequest } from '../models/TimeSeriesRequest';
import { TimeSeriesResponse } from '../models/TimeSeriesResponse';
import { TimeSlot } from '../models/TimeSlot';
import { TollData } from '../models/TollData';
import { TourCreationRequestTO } from '../models/TourCreationRequestTO';
import { TourTO } from '../models/TourTO';
import { Train } from '../models/Train';
import { TrainLocationTO } from '../models/TrainLocationTO';
import { TrainOperator } from '../models/TrainOperator';
import { TrainOperatorBooking } from '../models/TrainOperatorBooking';
import { TrainOperatorUserTO } from '../models/TrainOperatorUserTO';
import { TrainOperatorUserTOLanguage } from '../models/TrainOperatorUserTOLanguage';
import { TruckerBlocklistRequestTO } from '../models/TruckerBlocklistRequestTO';
import { TruckerBlocklistResponseTO } from '../models/TruckerBlocklistResponseTO';
import { TruckerForForwarderTO } from '../models/TruckerForForwarderTO';
import { TruckerIdentificationTO } from '../models/TruckerIdentificationTO';
import { TruckerInformationTO } from '../models/TruckerInformationTO';
import { UpdateTruckerForForwarderTO } from '../models/UpdateTruckerForForwarderTO';
import { User } from '../models/User';
import { VerifyEmail } from '../models/VerifyEmail';
import { WasteData } from '../models/WasteData';
import { WebappUser } from '../models/WebappUser';
import { ObservableAcceptanceRestrictionsApi } from './ObservableAPI';

import { AcceptanceRestrictionsApiRequestFactory, AcceptanceRestrictionsApiResponseProcessor} from "../apis/AcceptanceRestrictionsApi";
export class PromiseAcceptanceRestrictionsApi {
    private api: ObservableAcceptanceRestrictionsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AcceptanceRestrictionsApiRequestFactory,
        responseProcessor?: AcceptanceRestrictionsApiResponseProcessor
    ) {
        this.api = new ObservableAcceptanceRestrictionsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Creates acceptance deadline for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param acceptanceDeadline 
     */
    public createAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadline: AcceptanceDeadline, _options?: Configuration): Promise<AcceptanceDeadline> {
        const result = this.api.createAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadline, _options);
        return result.toPromise();
    }

    /**
     * Creates one to one restriction for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param oneToOneRestriction 
     */
    public createOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestriction: OneToOneRestriction, _options?: Configuration): Promise<OneToOneRestriction> {
        const result = this.api.createOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestriction, _options);
        return result.toPromise();
    }

    /**
     * Deletes acceptance deadline for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param acceptanceDeadlineId id of acceptance deadline
     */
    public deleteAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, _options?: Configuration): Promise<void> {
        const result = this.api.deleteAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, _options);
        return result.toPromise();
    }

    /**
     * Deletes one to one restriction for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param oneToOneRestrictionId id of acceptance deadline
     */
    public deleteOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, _options?: Configuration): Promise<void> {
        const result = this.api.deleteOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, _options);
        return result.toPromise();
    }

    /**
     * Gets all acceptance deadlines of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getAcceptanceDeadlinesForTerminal(userId: string, terminalId: number, _options?: Configuration): Promise<Array<AcceptanceDeadline>> {
        const result = this.api.getAcceptanceDeadlinesForTerminal(userId, terminalId, _options);
        return result.toPromise();
    }

    /**
     * Gets all one to one  restrictions of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getOneToOneRestrictionsForTerminal(userId: string, terminalId: number, _options?: Configuration): Promise<Array<OneToOneRestriction>> {
        const result = this.api.getOneToOneRestrictionsForTerminal(userId, terminalId, _options);
        return result.toPromise();
    }

    /**
     * Updates acceptance deadline for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param acceptanceDeadlineId id of acceptance deadline
     * @param acceptanceDeadline 
     */
    public updateAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, acceptanceDeadline: AcceptanceDeadline, _options?: Configuration): Promise<AcceptanceDeadline> {
        const result = this.api.updateAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, acceptanceDeadline, _options);
        return result.toPromise();
    }

    /**
     * Updates one to one restriction  for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param oneToOneRestrictionId id of acceptance deadline
     * @param oneToOneRestriction 
     */
    public updateOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, oneToOneRestriction: OneToOneRestriction, _options?: Configuration): Promise<OneToOneRestriction> {
        const result = this.api.updateOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, oneToOneRestriction, _options);
        return result.toPromise();
    }


}



import { ObservableForwarderBookingsApi } from './ObservableAPI';

import { ForwarderBookingsApiRequestFactory, ForwarderBookingsApiResponseProcessor} from "../apis/ForwarderBookingsApi";
export class PromiseForwarderBookingsApi {
    private api: ObservableForwarderBookingsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderBookingsApiRequestFactory,
        responseProcessor?: ForwarderBookingsApiResponseProcessor
    ) {
        this.api = new ObservableForwarderBookingsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Cancels a booking
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingId Booking id
     */
    public cancelBooking(userId: string, forwarderId: number, truckerId: string, bookingId: number, _options?: Configuration): Promise<void> {
        const result = this.api.cancelBooking(userId, forwarderId, truckerId, bookingId, _options);
        return result.toPromise();
    }

    /**
     * Performs a booking for a trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingRequest 
     */
    public createBookingForTrucker(userId: string, forwarderId: number, truckerId: string, bookingRequest: BookingRequest, _options?: Configuration): Promise<Booking> {
        const result = this.api.createBookingForTrucker(userId, forwarderId, truckerId, bookingRequest, _options);
        return result.toPromise();
    }

    /**
     * Get Booking by id for trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingId Booking id
     */
    public getBookingByIdForTrucker(userId: string, forwarderId: number, truckerId: string, bookingId: number, _options?: Configuration): Promise<BookingForForwarderTO> {
        const result = this.api.getBookingByIdForTrucker(userId, forwarderId, truckerId, bookingId, _options);
        return result.toPromise();
    }

    /**
     * Export booking transhipment confirmation as pdf
     * Export booking transhipment confirmation as pdf
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingId Booking id
     */
    public getTranshipmentConfirmationDocument(userId: string, forwarderId: number, truckerId: string, bookingId: number, _options?: Configuration): Promise<string> {
        const result = this.api.getTranshipmentConfirmationDocument(userId, forwarderId, truckerId, bookingId, _options);
        return result.toPromise();
    }

    /**
     * Get all Bookings for forwarder
     * Get all Bookings for forwarder
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param bookingsFilterRequestTO 
     * @param page 
     * @param size 
     */
    public listBookingsForForwarder(userId: string, forwarderId: number, bookingsFilterRequestTO: BookingsFilterRequestTO, page?: number, size?: number, _options?: Configuration): Promise<Array<BookingForForwarderTO>> {
        const result = this.api.listBookingsForForwarder(userId, forwarderId, bookingsFilterRequestTO, page, size, _options);
        return result.toPromise();
    }

    /**
     * Gets all bookings for a specific trucker
     * Get all Bookings for trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId UUID that comes from firebase authentication
     * @param page 
     * @param size 
     */
    public listBookingsForTrucker(userId: string, forwarderId: number, truckerId: string, page?: number, size?: number, _options?: Configuration): Promise<Array<BookingForForwarderTO>> {
        const result = this.api.listBookingsForTrucker(userId, forwarderId, truckerId, page, size, _options);
        return result.toPromise();
    }


}



import { ObservableForwarderContainersApi } from './ObservableAPI';

import { ForwarderContainersApiRequestFactory, ForwarderContainersApiResponseProcessor} from "../apis/ForwarderContainersApi";
export class PromiseForwarderContainersApi {
    private api: ObservableForwarderContainersApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderContainersApiRequestFactory,
        responseProcessor?: ForwarderContainersApiResponseProcessor
    ) {
        this.api = new ObservableForwarderContainersApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Downloads a csv with getStatus list requests and responses
     * Downloads a csv with getStatus list requests and responses
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     * @param getStatusDownloadCSVRequestTO 
     */
    public downloadGetStatusCsv(userId: string, forwarderId: number, getStatusDownloadCSVRequestTO: GetStatusDownloadCSVRequestTO, _options?: Configuration): Promise<string> {
        const result = this.api.downloadGetStatusCsv(userId, forwarderId, getStatusDownloadCSVRequestTO, _options);
        return result.toPromise();
    }

    /**
     * Indicates whether a booking is possible to be booked and supplies necessary information.
     * Requests the status of a loading unit
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     * @param terminalId The terminal id of the terminal to be called
     * @param getStatusRequest 
     * @param requestType place where get status was triggered
     */
    public getStatus1(userId: string, forwarderId: number, terminalId: number, getStatusRequest: GetStatusRequest, requestType?: 'single_get_status' | 'list_get_status', _options?: Configuration): Promise<GetStatusResponse> {
        const result = this.api.getStatus1(userId, forwarderId, terminalId, getStatusRequest, requestType, _options);
        return result.toPromise();
    }

    /**
     * parses csv file for container data and returns getStatusRequests to be done by webapp.
     * parses csv file for container data and returns getStatusRequests to be done by webapp
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     * @param file Csv file containing container data to be parsed
     */
    public parseGetStatusFile(userId: string, forwarderId: number, file: HttpFile, _options?: Configuration): Promise<ParseGetStatusFileResponse> {
        const result = this.api.parseGetStatusFile(userId, forwarderId, file, _options);
        return result.toPromise();
    }


}



import { ObservableForwarderOperatorForwardersApi } from './ObservableAPI';

import { ForwarderOperatorForwardersApiRequestFactory, ForwarderOperatorForwardersApiResponseProcessor} from "../apis/ForwarderOperatorForwardersApi";
export class PromiseForwarderOperatorForwardersApi {
    private api: ObservableForwarderOperatorForwardersApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderOperatorForwardersApiRequestFactory,
        responseProcessor?: ForwarderOperatorForwardersApiResponseProcessor
    ) {
        this.api = new ObservableForwarderOperatorForwardersApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * gets all forwarders
     * @param userId UUID that comes from firebase authentication
     */
    public getAllForwarders(userId: string, _options?: Configuration): Promise<Array<Forwarder>> {
        const result = this.api.getAllForwarders(userId, _options);
        return result.toPromise();
    }

    /**
     * gets forwarder by id
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     */
    public getForwarderById(userId: string, forwarderId: number, _options?: Configuration): Promise<Forwarder> {
        const result = this.api.getForwarderById(userId, forwarderId, _options);
        return result.toPromise();
    }


}



import { ObservableForwarderOperatorTerminalApiApi } from './ObservableAPI';

import { ForwarderOperatorTerminalApiApiRequestFactory, ForwarderOperatorTerminalApiApiResponseProcessor} from "../apis/ForwarderOperatorTerminalApiApi";
export class PromiseForwarderOperatorTerminalApiApi {
    private api: ObservableForwarderOperatorTerminalApiApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderOperatorTerminalApiApiRequestFactory,
        responseProcessor?: ForwarderOperatorTerminalApiApiResponseProcessor
    ) {
        this.api = new ObservableForwarderOperatorTerminalApiApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Gets all one to one  restrictions of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the Forwarder
     * @param terminalId id of requested terminal
     */
    public getOneToOneRestrictionsForForwarderOperatorForTerminal(userId: string, forwarderId: number, terminalId: number, _options?: Configuration): Promise<Array<OneToOneRestriction>> {
        const result = this.api.getOneToOneRestrictionsForForwarderOperatorForTerminal(userId, forwarderId, terminalId, _options);
        return result.toPromise();
    }

    /**
     * Takes a User ID, Forwarder ID  and returns all existing terminals
     * Gets all terminals for forwarder operator
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of Forwarder
     */
    public getTerminalsForForwarderOperator(userId: string, forwarderId: number, _options?: Configuration): Promise<Array<TerminalReduced>> {
        const result = this.api.getTerminalsForForwarderOperator(userId, forwarderId, _options);
        return result.toPromise();
    }


}



import { ObservableForwarderTruckersApiApi } from './ObservableAPI';

import { ForwarderTruckersApiApiRequestFactory, ForwarderTruckersApiApiResponseProcessor} from "../apis/ForwarderTruckersApiApi";
export class PromiseForwarderTruckersApiApi {
    private api: ObservableForwarderTruckersApiApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderTruckersApiApiRequestFactory,
        responseProcessor?: ForwarderTruckersApiApiResponseProcessor
    ) {
        this.api = new ObservableForwarderTruckersApiApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Approves a trucker to have this forwarder
     * Approves a trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker to update
     * @param forwarderApproval 
     */
    public approveTrucker(userId: string, forwarderId: number, truckerId: string, forwarderApproval: ForwarderApproval, _options?: Configuration): Promise<void> {
        const result = this.api.approveTrucker(userId, forwarderId, truckerId, forwarderApproval, _options);
        return result.toPromise();
    }

    /**
     * Deletes current trucker
     * Delete trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker to get
     */
    public deleteTrucker(userId: string, forwarderId: number, truckerId: string, _options?: Configuration): Promise<void> {
        const result = this.api.deleteTrucker(userId, forwarderId, truckerId, _options);
        return result.toPromise();
    }

    /**
     * Gets all approved truckers for forwarder
     * Gets all approved Truckers for forwarder
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     */
    public getAllApprovedTruckers(userId: string, forwarderId: number, _options?: Configuration): Promise<Array<User>> {
        const result = this.api.getAllApprovedTruckers(userId, forwarderId, _options);
        return result.toPromise();
    }

    /**
     * Gets all current available truckers for forwarder
     * Gets all registered Truckers for forwarder
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param page 
     * @param size 
     */
    public getAllTruckersForForwarder(userId: string, forwarderId: number, page?: number, size?: number, _options?: Configuration): Promise<Array<TruckerForForwarderTO>> {
        const result = this.api.getAllTruckersForForwarder(userId, forwarderId, page, size, _options);
        return result.toPromise();
    }

    /**
     * Gets trucker information by his id 
     * Gets trucker by id
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId 
     */
    public getTruckerById(userId: string, forwarderId: number, truckerId: string, _options?: Configuration): Promise<TruckerForForwarderTO> {
        const result = this.api.getTruckerById(userId, forwarderId, truckerId, _options);
        return result.toPromise();
    }

    /**
     * Updates truck driver information
     * Updates trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker to get
     * @param updateTruckerForForwarderTO 
     */
    public updateTrucker(userId: string, forwarderId: number, truckerId: string, updateTruckerForForwarderTO: UpdateTruckerForForwarderTO, _options?: Configuration): Promise<TruckerForForwarderTO> {
        const result = this.api.updateTrucker(userId, forwarderId, truckerId, updateTruckerForForwarderTO, _options);
        return result.toPromise();
    }


}



import { ObservableForwarderUserApi } from './ObservableAPI';

import { ForwarderUserApiRequestFactory, ForwarderUserApiResponseProcessor} from "../apis/ForwarderUserApi";
export class PromiseForwarderUserApi {
    private api: ObservableForwarderUserApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderUserApiRequestFactory,
        responseProcessor?: ForwarderUserApiResponseProcessor
    ) {
        this.api = new ObservableForwarderUserApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Updates a Forwarder User Role
     * @param userId Admin user id
     * @param forwarderUserTO 
     */
    public addRoleForForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, _options?: Configuration): Promise<ForwarderUserTO> {
        const result = this.api.addRoleForForwarderUser(userId, forwarderUserTO, _options);
        return result.toPromise();
    }

    /**
     * Create a Forwarder User
     * @param userId Admin user id
     * @param createForwarderUserTO 
     */
    public createUserForForwarder(userId: string, createForwarderUserTO: CreateForwarderUserTO, _options?: Configuration): Promise<ForwarderUserTO> {
        const result = this.api.createUserForForwarder(userId, createForwarderUserTO, _options);
        return result.toPromise();
    }

    /**
     * Deletes forwarder user entity
     * @param userId Admin user id
     * @param forwarderUserId User to be deleted
     */
    public removeForwarderUserRole(userId: string, forwarderUserId: string, _options?: Configuration): Promise<void> {
        const result = this.api.removeForwarderUserRole(userId, forwarderUserId, _options);
        return result.toPromise();
    }

    /**
     * Updates forwarder user entity
     * @param userId Admin user id
     * @param forwarderUserTO 
     */
    public updateForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, _options?: Configuration): Promise<ForwarderUserTO> {
        const result = this.api.updateForwarderUser(userId, forwarderUserTO, _options);
        return result.toPromise();
    }


}



import { ObservableIspsAdminUsersApi } from './ObservableAPI';

import { IspsAdminUsersApiRequestFactory, IspsAdminUsersApiResponseProcessor} from "../apis/IspsAdminUsersApi";
export class PromiseIspsAdminUsersApi {
    private api: ObservableIspsAdminUsersApi

    public constructor(
        configuration: Configuration,
        requestFactory?: IspsAdminUsersApiRequestFactory,
        responseProcessor?: IspsAdminUsersApiResponseProcessor
    ) {
        this.api = new ObservableIspsAdminUsersApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Deletes TruckerIdentification tries and automatically approves
     * @param userId UUID that comes from firebase authentication
     * @param truckerId Trucker id that needs to be reset
     */
    public deleteTruckerIdentifications(userId: string, truckerId: string, _options?: Configuration): Promise<IspsTruckerTO> {
        const result = this.api.deleteTruckerIdentifications(userId, truckerId, _options);
        return result.toPromise();
    }

    /**
     * get for trucker in regards of identification and licensing
     * get Isps Trucker
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be retrieved
     * @param request 
     */
    public getIspsTrucker(userId: string, truckerId: string, request: IspsTruckerTO, _options?: Configuration): Promise<IspsTruckerTO> {
        const result = this.api.getIspsTrucker(userId, truckerId, request, _options);
        return result.toPromise();
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * get Isps Truckers
     * @param userId UUID that comes from firebase authentication
     * @param documentIdentificationStatus Document Identification Status
     * @param terrorDatabaseStatus Terror Database Status
     * @param truckerId Trucker ID
     * @param page Page number
     * @param size 
     */
    public getIspsTruckers(userId: string, documentIdentificationStatus?: 'ACCEPTED' | 'PENDING' | 'DENIED' | 'WARN' | 'DENIED_PRELIMINARY' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED' | 'FAILED', terrorDatabaseStatus?: 'OK' | 'WARNING' | 'RED_ALERT' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED', truckerId?: string, page?: number, size?: number, _options?: Configuration): Promise<Array<IspsTruckerTO>> {
        const result = this.api.getIspsTruckers(userId, documentIdentificationStatus, terrorDatabaseStatus, truckerId, page, size, _options);
        return result.toPromise();
    }

    /**
     * cerate for trucker in regards of identification and licensing
     * create isps trucker log
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be edited
     * @param ispsTruckerChangeRequestTO 
     */
    public ispsTruckerLog(userId: string, truckerId: string, ispsTruckerChangeRequestTO: IspsTruckerChangeRequestTO, _options?: Configuration): Promise<IspsTruckerTO> {
        const result = this.api.ispsTruckerLog(userId, truckerId, ispsTruckerChangeRequestTO, _options);
        return result.toPromise();
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * Patch Isps Trucker
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be approved
     * @param changeId Id of the change to be approved
     * @param ispsChangeApprovalRequestTO 
     */
    public patchIspsTrucker(userId: string, truckerId: string, changeId: number, ispsChangeApprovalRequestTO: IspsChangeApprovalRequestTO, _options?: Configuration): Promise<void> {
        const result = this.api.patchIspsTrucker(userId, truckerId, changeId, ispsChangeApprovalRequestTO, _options);
        return result.toPromise();
    }


}



import { ObservableIspsTerminalOperatorBlocklistApi } from './ObservableAPI';

import { IspsTerminalOperatorBlocklistApiRequestFactory, IspsTerminalOperatorBlocklistApiResponseProcessor} from "../apis/IspsTerminalOperatorBlocklistApi";
export class PromiseIspsTerminalOperatorBlocklistApi {
    private api: ObservableIspsTerminalOperatorBlocklistApi

    public constructor(
        configuration: Configuration,
        requestFactory?: IspsTerminalOperatorBlocklistApiRequestFactory,
        responseProcessor?: IspsTerminalOperatorBlocklistApiResponseProcessor
    ) {
        this.api = new ObservableIspsTerminalOperatorBlocklistApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Creates blocklist entry for isps terminal
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested facility
     * @param truckerBlocklistRequestTO 
     */
    public createBlockListEntryForIspsTerminal(userId: string, facilityId: number, truckerBlocklistRequestTO: TruckerBlocklistRequestTO, _options?: Configuration): Promise<TruckerBlocklistResponseTO> {
        const result = this.api.createBlockListEntryForIspsTerminal(userId, facilityId, truckerBlocklistRequestTO, _options);
        return result.toPromise();
    }

    /**
     * Deletes  blocklist entry for isps terminal
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested facility
     * @param blocklistId id of blocklist entry
     */
    public deleteBlockListEntryForIspsTerminal(userId: string, facilityId: number, blocklistId: number, _options?: Configuration): Promise<void> {
        const result = this.api.deleteBlockListEntryForIspsTerminal(userId, facilityId, blocklistId, _options);
        return result.toPromise();
    }

    /**
     * Gets all blocklist entries of a facility
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested terminal
     */
    public getBlocklistEntiresForFacility(userId: string, facilityId: number, _options?: Configuration): Promise<Array<TruckerBlocklistResponseTO>> {
        const result = this.api.getBlocklistEntiresForFacility(userId, facilityId, _options);
        return result.toPromise();
    }


}



import { ObservableMirrorAppUserVersionsApi } from './ObservableAPI';

import { MirrorAppUserVersionsApiRequestFactory, MirrorAppUserVersionsApiResponseProcessor} from "../apis/MirrorAppUserVersionsApi";
export class PromiseMirrorAppUserVersionsApi {
    private api: ObservableMirrorAppUserVersionsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: MirrorAppUserVersionsApiRequestFactory,
        responseProcessor?: MirrorAppUserVersionsApiResponseProcessor
    ) {
        this.api = new ObservableMirrorAppUserVersionsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Gets all specific versions per query filtering
     * @param userId UUID that comes from firebase authentication
     * @param appVersion 
     */
    public getAllHostedAppVersions(userId: string, appVersion: 'android' | 'ios', _options?: Configuration): Promise<Array<AppVersionResponse>> {
        const result = this.api.getAllHostedAppVersions(userId, appVersion, _options);
        return result.toPromise();
    }


}



import { ObservableStatisticsApi } from './ObservableAPI';

import { StatisticsApiRequestFactory, StatisticsApiResponseProcessor} from "../apis/StatisticsApi";
export class PromiseStatisticsApi {
    private api: ObservableStatisticsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: StatisticsApiRequestFactory,
        responseProcessor?: StatisticsApiResponseProcessor
    ) {
        this.api = new ObservableStatisticsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Gets a specific statistic value
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param rangedTerminalStatisticsValueRequest 
     */
    public getStatisticFloatValue(userId: string, terminalId: number, rangedTerminalStatisticsValueRequest: RangedTerminalStatisticsValueRequest, _options?: Configuration): Promise<RangedTerminalStatisticsValueResponse> {
        const result = this.api.getStatisticFloatValue(userId, terminalId, rangedTerminalStatisticsValueRequest, _options);
        return result.toPromise();
    }

    /**
     * Gets a specific statistic timeSeries
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param timeSeriesRequest 
     */
    public getTimeSeries(userId: string, terminalId: number, timeSeriesRequest: TimeSeriesRequest, _options?: Configuration): Promise<TimeSeriesResponse> {
        const result = this.api.getTimeSeries(userId, terminalId, timeSeriesRequest, _options);
        return result.toPromise();
    }


}



import { ObservableTerminalOperatorUserApi } from './ObservableAPI';

import { TerminalOperatorUserApiRequestFactory, TerminalOperatorUserApiResponseProcessor} from "../apis/TerminalOperatorUserApi";
export class PromiseTerminalOperatorUserApi {
    private api: ObservableTerminalOperatorUserApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TerminalOperatorUserApiRequestFactory,
        responseProcessor?: TerminalOperatorUserApiResponseProcessor
    ) {
        this.api = new ObservableTerminalOperatorUserApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Updates a terminal operator User Role
     * @param userId Admin user id
     * @param terminalOperatorUserTO 
     */
    public addTerminalOperatorUserRole(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, _options?: Configuration): Promise<TerminalOperatorUserTO> {
        const result = this.api.addTerminalOperatorUserRole(userId, terminalOperatorUserTO, _options);
        return result.toPromise();
    }

    /**
     * Create a Terminal Operator User
     * @param userId Admin user id
     * @param createTerminalOperatorUserTO 
     */
    public createUserForTerminalOperator(userId: string, createTerminalOperatorUserTO: CreateTerminalOperatorUserTO, _options?: Configuration): Promise<TerminalOperatorUserTO> {
        const result = this.api.createUserForTerminalOperator(userId, createTerminalOperatorUserTO, _options);
        return result.toPromise();
    }

    /**
     * Deletes terminal user entity
     * @param userId Admin user id
     * @param terminalOperatorUserId User to be deleted
     */
    public removeTerminalOperatorUserRole(userId: string, terminalOperatorUserId: string, _options?: Configuration): Promise<void> {
        const result = this.api.removeTerminalOperatorUserRole(userId, terminalOperatorUserId, _options);
        return result.toPromise();
    }

    /**
     * Updates terminal operator user entity
     * @param userId Admin user id
     * @param terminalOperatorUserTO 
     */
    public updateTerminalOperatorUser(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, _options?: Configuration): Promise<TerminalOperatorUserTO> {
        const result = this.api.updateTerminalOperatorUser(userId, terminalOperatorUserTO, _options);
        return result.toPromise();
    }


}



import { ObservableTerminalUsersApi } from './ObservableAPI';

import { TerminalUsersApiRequestFactory, TerminalUsersApiResponseProcessor} from "../apis/TerminalUsersApi";
export class PromiseTerminalUsersApi {
    private api: ObservableTerminalUsersApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TerminalUsersApiRequestFactory,
        responseProcessor?: TerminalUsersApiResponseProcessor
    ) {
        this.api = new ObservableTerminalUsersApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * This can only be done by the logged in user.
     * Delete user
     * @param userId UUID that comes from firebase authentication
     */
    public deleteUser(userId: string, _options?: Configuration): Promise<void> {
        const result = this.api.deleteUser(userId, _options);
        return result.toPromise();
    }

    /**
     * Gets all terminals for user
     * @param userId UUID that comes from firebase authentication
     */
    public getTerminalsForUser(userId: string, _options?: Configuration): Promise<Array<Terminal>> {
        const result = this.api.getTerminalsForUser(userId, _options);
        return result.toPromise();
    }

    /**
     * Get user by Id
     * Get user
     * @param userId UUID that comes from firebase authentication
     */
    public getUserById(userId: string, _options?: Configuration): Promise<WebappUser> {
        const result = this.api.getUserById(userId, _options);
        return result.toPromise();
    }

    /**
     * resets password for terminal operator
     * reset password
     * @param resetPassword 
     */
    public resetPassword(resetPassword: ResetPassword, _options?: Configuration): Promise<void> {
        const result = this.api.resetPassword(resetPassword, _options);
        return result.toPromise();
    }

    /**
     * This can only be done by the logged in user.
     * Updates user
     * @param userId UUID that comes from firebase authentication
     * @param webappUser 
     */
    public updateUser(userId: string, webappUser: WebappUser, _options?: Configuration): Promise<WebappUser> {
        const result = this.api.updateUser(userId, webappUser, _options);
        return result.toPromise();
    }

    /**
     * verify email for terminal operator
     * verify email
     * @param verifyEmail 
     */
    public verifyEmail(verifyEmail: VerifyEmail, _options?: Configuration): Promise<void> {
        const result = this.api.verifyEmail(verifyEmail, _options);
        return result.toPromise();
    }


}



import { ObservableTerminalsApi } from './ObservableAPI';

import { TerminalsApiRequestFactory, TerminalsApiResponseProcessor} from "../apis/TerminalsApi";
export class PromiseTerminalsApi {
    private api: ObservableTerminalsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TerminalsApiRequestFactory,
        responseProcessor?: TerminalsApiResponseProcessor
    ) {
        this.api = new ObservableTerminalsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Gets a specific terminal module by Id
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param moduleId id of requested terminal module
     */
    public getModuleById(userId: string, terminalId: number, moduleId: number, _options?: Configuration): Promise<TerminalModule> {
        const result = this.api.getModuleById(userId, terminalId, moduleId, _options);
        return result.toPromise();
    }

    /**
     * Gets all modules of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getModulesForTerminal(userId: string, terminalId: number, _options?: Configuration): Promise<Array<TerminalModule>> {
        const result = this.api.getModulesForTerminal(userId, terminalId, _options);
        return result.toPromise();
    }

    /**
     * Gets a specific terminal by Id
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getTerminalById(userId: string, terminalId: number, _options?: Configuration): Promise<Terminal> {
        const result = this.api.getTerminalById(userId, terminalId, _options);
        return result.toPromise();
    }


}



import { ObservableTrainOperatorBookingsApi } from './ObservableAPI';

import { TrainOperatorBookingsApiRequestFactory, TrainOperatorBookingsApiResponseProcessor} from "../apis/TrainOperatorBookingsApi";
export class PromiseTrainOperatorBookingsApi {
    private api: ObservableTrainOperatorBookingsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TrainOperatorBookingsApiRequestFactory,
        responseProcessor?: TrainOperatorBookingsApiResponseProcessor
    ) {
        this.api = new ObservableTrainOperatorBookingsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * confirms bookings from train operator
     * @param userId UUID that comes from firebase authentication
     * @param trainOperatorId id of train operator
     * @param terminalId id of requested terminal
     * @param confirmBookingEntries 
     */
    public confirmBookingEntries(userId: string, trainOperatorId: number, terminalId: number, confirmBookingEntries: ConfirmBookingEntries, _options?: Configuration): Promise<void> {
        const result = this.api.confirmBookingEntries(userId, trainOperatorId, terminalId, confirmBookingEntries, _options);
        return result.toPromise();
    }

    /**
     * Gets current available train operators
     * @param userId UUID that comes from firebase authentication
     */
    public getAvailableTrainOperators(userId: string, _options?: Configuration): Promise<Array<TrainOperator>> {
        const result = this.api.getAvailableTrainOperators(userId, _options);
        return result.toPromise();
    }

    /**
     * Gets bookings for trainOperator and terminal(s)
     * @param userId UUID that comes from firebase authentication
     * @param trainOperatorId id of train operator
     * @param terminalId id of requested terminal
     * @param page page
     * @param size size
     * @param active boolean whether active or non active bookings should be supplied
     */
    public getBookingsForTrainOperator(userId: string, trainOperatorId: number, terminalId: number, page: number, size: number, active?: boolean, _options?: Configuration): Promise<Array<TrainOperatorBooking>> {
        const result = this.api.getBookingsForTrainOperator(userId, trainOperatorId, terminalId, page, size, active, _options);
        return result.toPromise();
    }


}



import { ObservableTrainOperatorUserApi } from './ObservableAPI';

import { TrainOperatorUserApiRequestFactory, TrainOperatorUserApiResponseProcessor} from "../apis/TrainOperatorUserApi";
export class PromiseTrainOperatorUserApi {
    private api: ObservableTrainOperatorUserApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TrainOperatorUserApiRequestFactory,
        responseProcessor?: TrainOperatorUserApiResponseProcessor
    ) {
        this.api = new ObservableTrainOperatorUserApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Updates a Train operator User Role
     * @param userId Admin user id
     * @param trainOperatorUserTO 
     */
    public addTrainOperatorUserRole(userId: string, trainOperatorUserTO: TrainOperatorUserTO, _options?: Configuration): Promise<TrainOperatorUserTO> {
        const result = this.api.addTrainOperatorUserRole(userId, trainOperatorUserTO, _options);
        return result.toPromise();
    }

    /**
     * Create a Train Operator User
     * @param userId Admin user id
     * @param createTrainOperatorUserTO 
     */
    public createUserForTrainOperator(userId: string, createTrainOperatorUserTO: CreateTrainOperatorUserTO, _options?: Configuration): Promise<TrainOperatorUserTO> {
        const result = this.api.createUserForTrainOperator(userId, createTrainOperatorUserTO, _options);
        return result.toPromise();
    }

    /**
     * Deletes train operator user entity
     * @param userId Admin user id
     * @param trainOperatorUserId User to be deleted
     */
    public removeTrainOperatorUserRole(userId: string, trainOperatorUserId: string, _options?: Configuration): Promise<void> {
        const result = this.api.removeTrainOperatorUserRole(userId, trainOperatorUserId, _options);
        return result.toPromise();
    }

    /**
     * Updates train operator user entity
     * @param userId Admin user id
     * @param trainOperatorUserTO 
     */
    public updateTrainOperatorUser(userId: string, trainOperatorUserTO: TrainOperatorUserTO, _options?: Configuration): Promise<TrainOperatorUserTO> {
        const result = this.api.updateTrainOperatorUser(userId, trainOperatorUserTO, _options);
        return result.toPromise();
    }


}



import { ObservableWebappDepotsApi } from './ObservableAPI';

import { WebappDepotsApiRequestFactory, WebappDepotsApiResponseProcessor} from "../apis/WebappDepotsApi";
export class PromiseWebappDepotsApi {
    private api: ObservableWebappDepotsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: WebappDepotsApiRequestFactory,
        responseProcessor?: WebappDepotsApiResponseProcessor
    ) {
        this.api = new ObservableWebappDepotsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Creates a new tour object in the data base with the contained bookings
     * Creates Tour
     * @param userId UUID that comes from firebase authentication
     * @param depotId UUID that defines the depot
     * @param tourCreationRequestTO 
     */
    public createTour(userId: string, depotId: number, tourCreationRequestTO: TourCreationRequestTO, _options?: Configuration): Promise<TourTO> {
        const result = this.api.createTour(userId, depotId, tourCreationRequestTO, _options);
        return result.toPromise();
    }

    /**
     * Get all customers that belong to a depot
     * Get list of all customers per depot-facilities
     * @param userId UUID that comes from firebase authentication
     * @param depotId UUID that defines the depot
     */
    public getAllCustomers(userId: string, depotId: number, _options?: Configuration): Promise<Array<CustomerTO>> {
        const result = this.api.getAllCustomers(userId, depotId, _options);
        return result.toPromise();
    }

    /**
     * Get status of multiple container requests
     * Get status of multiple container requests
     * @param userId UUID that comes from firebase authentication
     * @param depotId UUID that defines the depot
     * @param depotGetStatusMultiRequestTO 
     */
    public getStatus(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, _options?: Configuration): Promise<DepotGetStatusMultiResponseTO> {
        const result = this.api.getStatus(userId, depotId, depotGetStatusMultiRequestTO, _options);
        return result.toPromise();
    }


}



import { ObservableWebappStatisticsForForwarderApi } from './ObservableAPI';

import { WebappStatisticsForForwarderApiRequestFactory, WebappStatisticsForForwarderApiResponseProcessor} from "../apis/WebappStatisticsForForwarderApi";
export class PromiseWebappStatisticsForForwarderApi {
    private api: ObservableWebappStatisticsForForwarderApi

    public constructor(
        configuration: Configuration,
        requestFactory?: WebappStatisticsForForwarderApiRequestFactory,
        responseProcessor?: WebappStatisticsForForwarderApiResponseProcessor
    ) {
        this.api = new ObservableWebappStatisticsForForwarderApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Gets statistical float value for the forwarders
     * Gets a specific statistic value
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param constantForwarderStatisticValueRequestTO 
     */
    public getStatisticFloatValue1(userId: string, forwarderId: number, constantForwarderStatisticValueRequestTO: ConstantForwarderStatisticValueRequestTO, _options?: Configuration): Promise<ConstantForwarderStatisticFloatValueResponseTO> {
        const result = this.api.getStatisticFloatValue1(userId, forwarderId, constantForwarderStatisticValueRequestTO, _options);
        return result.toPromise();
    }


}



