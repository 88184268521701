<template>
  <div class="flex flex-col">
    <div class="toast toast-bottom toast-center">
      <div v-for="item in toasts" :key="item.id">
        <BasicToast :message="item.message" :level="item.level"> </BasicToast>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import BasicToast from "./BasicToast.vue";
import { useToastStore } from "@/observables/store/toast-store";

const toastStore = useToastStore();

const toasts = computed(() => toastStore.getToasts());
</script>
