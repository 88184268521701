/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ColorBorder } from '../models/ColorBorder';
import { HttpFile } from '../http/http';

/**
* Ranged terminal float value response
*/
export class RangedTerminalStatisticsValueResponse {
    'start': Date;
    'end': Date;
    'floatValue': number;
    'unit': string;
    'colorBorders': Array<ColorBorder>;
    /**
    * Statistic Name
    */
    'name': RangedTerminalStatisticsValueResponseNameEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "start",
            "baseName": "start",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "end",
            "baseName": "end",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "floatValue",
            "baseName": "floatValue",
            "type": "number",
            "format": "float"
        },
        {
            "name": "unit",
            "baseName": "unit",
            "type": "string",
            "format": ""
        },
        {
            "name": "colorBorders",
            "baseName": "colorBorders",
            "type": "Array<ColorBorder>",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "RangedTerminalStatisticsValueResponseNameEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return RangedTerminalStatisticsValueResponse.attributeTypeMap;
    }

    public constructor() {
    }
}


export type RangedTerminalStatisticsValueResponseNameEnum = "active_bookings" | "completed_bookings" | "canceled_bookings" | "time_register_success_transhipment" ;

