/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Ref & containerNumber as an StatusRequest object
*/
export class GetStatusRequest {
    'referenceNumber': string;
    'containerNumber'?: string;
    /**
    * Type of a Task
    */
    'type': GetStatusRequestTypeEnum;
    'terminalId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "containerNumber",
            "baseName": "containerNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "GetStatusRequestTypeEnum",
            "format": ""
        },
        {
            "name": "terminalId",
            "baseName": "terminalId",
            "type": "number",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return GetStatusRequest.attributeTypeMap;
    }

    public constructor() {
    }
}


export type GetStatusRequestTypeEnum = "pickup" | "delivery" ;

