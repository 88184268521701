/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* time slot for a booking
*/
export class TimeSlot {
    'id': number;
    'slotEnd': Date;
    'slotBegin': Date;
    /**
    * category of a time slot
    */
    'category'?: TimeSlotCategoryEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "slotEnd",
            "baseName": "slotEnd",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "slotBegin",
            "baseName": "slotBegin",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "category",
            "baseName": "category",
            "type": "TimeSlotCategoryEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TimeSlot.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TimeSlotCategoryEnum = "green" | "yellow" | "red" | "not_set" ;

