/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* pickup times object for loading unit
*/
export class PickupTimes {
    'plannedTrainArrival': Date;
    'availabilityTime': Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "plannedTrainArrival",
            "baseName": "plannedTrainArrival",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "availabilityTime",
            "baseName": "availabilityTime",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return PickupTimes.attributeTypeMap;
    }

    public constructor() {
    }
}

