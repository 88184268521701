/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TimeSlot } from '../models/TimeSlot';
import { HttpFile } from '../http/http';

/**
* Object use to request a booking
*/
export class BookingRequest {
    'containerNumber'?: string;
    'referenceNumber': string;
    'relatedBookingsIdentifier'?: string;
    'licensePlate': string;
    /**
    * Type of a Task
    */
    'type': BookingRequestTypeEnum;
    'timeSlot': TimeSlot;
    'terminalId': number;
    'forwarder': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "containerNumber",
            "baseName": "containerNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "relatedBookingsIdentifier",
            "baseName": "relatedBookingsIdentifier",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "licensePlate",
            "baseName": "licensePlate",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "BookingRequestTypeEnum",
            "format": ""
        },
        {
            "name": "timeSlot",
            "baseName": "timeSlot",
            "type": "TimeSlot",
            "format": ""
        },
        {
            "name": "terminalId",
            "baseName": "terminalId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "forwarder",
            "baseName": "forwarder",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return BookingRequest.attributeTypeMap;
    }

    public constructor() {
    }
}


export type BookingRequestTypeEnum = "pickup" | "delivery" ;

