/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Delivery/Pickup Booking of Container to be shown in twa for train operator
*/
export class TrainOperatorBooking {
    'bookingId': number;
    /**
    * Type of a Task
    */
    'type': TrainOperatorBookingTypeEnum;
    'referenceNumber': string;
    'terminalId': number;
    'containerNumber'?: string;
    'emptyWeight'?: number;
    'netWeight'?: number;
    'containerLength'?: number;
    'licensePlate'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'forwarder'?: string;
    'transhipmentTaskConfirmation'?: Date;
    'trainNumber'?: string;
    'latestLoadingTime'?: Date;
    'slotBegin'?: Date;
    'slotEnd'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "bookingId",
            "baseName": "bookingId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "TrainOperatorBookingTypeEnum",
            "format": ""
        },
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "terminalId",
            "baseName": "terminalId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "containerNumber",
            "baseName": "containerNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "emptyWeight",
            "baseName": "emptyWeight",
            "type": "number",
            "format": "double"
        },
        {
            "name": "netWeight",
            "baseName": "netWeight",
            "type": "number",
            "format": "double"
        },
        {
            "name": "containerLength",
            "baseName": "containerLength",
            "type": "number",
            "format": "double"
        },
        {
            "name": "licensePlate",
            "baseName": "licensePlate",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "forwarder",
            "baseName": "forwarder",
            "type": "string",
            "format": ""
        },
        {
            "name": "transhipmentTaskConfirmation",
            "baseName": "transhipmentTaskConfirmation",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "trainNumber",
            "baseName": "trainNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "latestLoadingTime",
            "baseName": "latestLoadingTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "slotBegin",
            "baseName": "slotBegin",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "slotEnd",
            "baseName": "slotEnd",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return TrainOperatorBooking.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TrainOperatorBookingTypeEnum = "pickup" | "delivery" ;

