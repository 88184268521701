import { Authenticator, getAuthService } from "../auth/auth-service";
import {
  IsomorphicFetchHttpLibrary,
  IspsAdminUsersApi,
  RequestContext,
  SecurityAuthentication,
  ServerConfiguration,
  TerminalUsersApi,
} from "./client";


const baseServerConfiguration = new ServerConfiguration(
  "https://frontend.dev.api.conroo.com",
  {}
)

class JWTSecurityAuthentication implements SecurityAuthentication {
  constructor(private readonly auth: Authenticator) {}

  getName(): string {
    return "JWTAuthentication";
  }
  applySecurityAuthentication(context: RequestContext): void | Promise<void> {
    return this.auth.getAccessToken().then((token) => {
      if (token != null) {
        context.setHeaderParam("Authorization", `Bearer ${token}`);
      }
    });
  }
}

const authService = getAuthService();

const jwtAuth = new JWTSecurityAuthentication(authService);


//TODO: make it double layer here too.
export const getAdminClient = () => {
  return new IspsAdminUsersApi({
    baseServer: baseServerConfiguration,
    httpApi: new IsomorphicFetchHttpLibrary(),
    middleware: [],
    authMethods: { default: jwtAuth },
  });
};

export const getUserClient = () => {
    return new TerminalUsersApi({
      baseServer: baseServerConfiguration,
      httpApi: new IsomorphicFetchHttpLibrary(),
      middleware: [],
      authMethods: { default: jwtAuth },
    });
  };

  