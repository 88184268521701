/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class TrainOperatorUserTOLanguage {
    'language'?: string;
    'displayName'?: string;
    'country'?: string;
    'variant'?: string;
    'script'?: string;
    'unicodeLocaleAttributes'?: Set<string>;
    'unicodeLocaleKeys'?: Set<string>;
    'displayLanguage'?: string;
    'displayScript'?: string;
    'displayCountry'?: string;
    'displayVariant'?: string;
    'extensionKeys'?: Set<string>;
    'iso3Language'?: string;
    'iso3Country'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "language",
            "baseName": "language",
            "type": "string",
            "format": ""
        },
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        },
        {
            "name": "variant",
            "baseName": "variant",
            "type": "string",
            "format": ""
        },
        {
            "name": "script",
            "baseName": "script",
            "type": "string",
            "format": ""
        },
        {
            "name": "unicodeLocaleAttributes",
            "baseName": "unicodeLocaleAttributes",
            "type": "Set<string>",
            "format": ""
        },
        {
            "name": "unicodeLocaleKeys",
            "baseName": "unicodeLocaleKeys",
            "type": "Set<string>",
            "format": ""
        },
        {
            "name": "displayLanguage",
            "baseName": "displayLanguage",
            "type": "string",
            "format": ""
        },
        {
            "name": "displayScript",
            "baseName": "displayScript",
            "type": "string",
            "format": ""
        },
        {
            "name": "displayCountry",
            "baseName": "displayCountry",
            "type": "string",
            "format": ""
        },
        {
            "name": "displayVariant",
            "baseName": "displayVariant",
            "type": "string",
            "format": ""
        },
        {
            "name": "extensionKeys",
            "baseName": "extensionKeys",
            "type": "Set<string>",
            "format": ""
        },
        {
            "name": "iso3Language",
            "baseName": "iso3Language",
            "type": "string",
            "format": ""
        },
        {
            "name": "iso3Country",
            "baseName": "iso3Country",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TrainOperatorUserTOLanguage.attributeTypeMap;
    }

    public constructor() {
    }
}

