/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Container } from '../models/Container';
import { HttpFile } from '../http/http';

export class DepotContainerBookingTO {
    'containerBookingId': number;
    'container'?: Container;
    'module'?: string;
    'position'?: string;
    'referenceNumber'?: string;
    /**
    * Type of a Task
    */
    'type': DepotContainerBookingTOTypeEnum;
    'customerId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "containerBookingId",
            "baseName": "containerBookingId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "container",
            "baseName": "container",
            "type": "Container",
            "format": ""
        },
        {
            "name": "module",
            "baseName": "module",
            "type": "string",
            "format": ""
        },
        {
            "name": "position",
            "baseName": "position",
            "type": "string",
            "format": ""
        },
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "DepotContainerBookingTOTypeEnum",
            "format": ""
        },
        {
            "name": "customerId",
            "baseName": "customerId",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return DepotContainerBookingTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type DepotContainerBookingTOTypeEnum = "pickup" | "delivery" ;

