/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Container } from '../models/Container';
import { DepotGetStatusRequestTO } from '../models/DepotGetStatusRequestTO';
import { HttpFile } from '../http/http';

export class DepotGetStatusResponseTO {
    'getStatusRequest': DepotGetStatusRequestTO;
    'validFrom'?: Date;
    'validTo'?: Date;
    'container'?: Container;
    'module'?: string;
    'position'?: string;
    'errorCode'?: DepotGetStatusResponseTOErrorCodeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "getStatusRequest",
            "baseName": "getStatusRequest",
            "type": "DepotGetStatusRequestTO",
            "format": ""
        },
        {
            "name": "validFrom",
            "baseName": "validFrom",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "validTo",
            "baseName": "validTo",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "container",
            "baseName": "container",
            "type": "Container",
            "format": ""
        },
        {
            "name": "module",
            "baseName": "module",
            "type": "string",
            "format": ""
        },
        {
            "name": "position",
            "baseName": "position",
            "type": "string",
            "format": ""
        },
        {
            "name": "errorCode",
            "baseName": "errorCode",
            "type": "DepotGetStatusResponseTOErrorCodeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return DepotGetStatusResponseTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type DepotGetStatusResponseTOErrorCodeEnum = "REQUEST_NOT_VALID" | "CTR_ID_INVALID" | "REFERENCE_NUMBER_MISSING" | "ISO_CODE_MISSING" | "CUSTOMER_KEY_MISSING" | "TURN_IN_REF_MISSING" | "CTR_ID_MISSING" | "CTR_ID_AND_ISO_CODE_MISSING" | "CTR_ID_AND_ISO_CODE_SET" | "CUSTOMER_NOT_FOUND" | "DEPOT_NOT_FOUND" | "AVIS_NOT_FOUND" | "AVIS_NOT_FOR_FACILITY" | "AVIS_NOT_FOR_CUSTOMER" | "AVIS_NOT_VALID" | "AVIS_NOT_FOR_CTRID" | "RELEASE_NOT_FOUND" | "RELEASE_NOT_OPEN" | "RELEASE_NOT_FOR_FACILITY" | "RELEASE_NOT_FOR_CUSTOMER" | "RELEASE_NOT_VALID" ;

