<template>
  <div v-if="props.isEditable">
    <TextInput
      v-model="editedValue"
      :value="editedValue"
      :placeholder="props.placeholder"
      @update:model-value="(t : string) => $emit('update:model-value', t)"
    ></TextInput>
  </div>
  <div v-else>{{ props.title }}</div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted } from "vue";

import TextInput from "@/components/TextInput.vue";

const props = defineProps<{
  title: string | undefined;
  inputValue: string | undefined;
  isEditable?: boolean;
  placeholder: string;
}>();

const editedValue = ref<string | undefined>(undefined);
onMounted(() => {
  editedValue.value = props.inputValue ?? props.title;
});
</script>
