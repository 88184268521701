import { WebappUser } from "@/services/api-client/client";
import { reactive } from "vue";

const storeData = reactive<{ user: WebappUser | null }>({
  user: null,
});

interface UserStore {
  getUser(): WebappUser | null;
  updateUser(user: WebappUser): void;
  removeUser(): void;
}

export const useUserStore = (): UserStore => {
  return {
    getUser: (): WebappUser | null => {
      if (storeData.user != null) {
        return storeData.user;
      }

      const localUser = localStorage.getItem("user");
      if (localUser != null && localUser.length !== 0) {
        const parsedLocalUser: WebappUser = JSON.parse(localUser);
        storeData.user = parsedLocalUser;
        return storeData.user;
      }

      return null;
    },
    updateUser: (user) => {
      storeData.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    removeUser: () => {
      storeData.user = null;
      localStorage.removeItem("user");
    },
  };
};
