/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* request body object
*/
export class TimeSeriesRequest {
    /**
    * Statistic Name
    */
    'name': TimeSeriesRequestNameEnum;
    'seriesStart': Date;
    'seriesEnd': Date;
    /**
    * Duration type on which to divide intervals
    */
    'duration': TimeSeriesRequestDurationEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "TimeSeriesRequestNameEnum",
            "format": ""
        },
        {
            "name": "seriesStart",
            "baseName": "seriesStart",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "seriesEnd",
            "baseName": "seriesEnd",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "TimeSeriesRequestDurationEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TimeSeriesRequest.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TimeSeriesRequestNameEnum = "active_bookings" | "completed_bookings" | "canceled_bookings" | "time_register_success_transhipment" ;
export type TimeSeriesRequestDurationEnum = "hour" | "day" | "week" ;

