/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Container } from '../models/Container';
import { TimeSlot } from '../models/TimeSlot';
import { HttpFile } from '../http/http';

/**
* Delivery/Pickup Booking of Container
*/
export class Booking {
    'bookingId': number;
    'relatedBookingsIdentifier'?: string;
    'referenceNumber'?: string;
    /**
    * Type of a Task
    */
    'type': BookingTypeEnum;
    'userId': string;
    'forwarderName'?: string;
    'module'?: string;
    'parkingLot'?: string;
    /**
    * state of a Booking
    */
    'bookingState': BookingBookingStateEnum;
    'containerUpdatable': boolean;
    'cancelledBy'?: string;
    'counterReason'?: BookingCounterReasonEnum;
    'terminalId': number;
    'terminalName': string;
    'facilityId': number;
    'facilityName': string;
    'facilityType': BookingFacilityTypeEnum;
    'container'?: Container;
    'timeSlot': TimeSlot;
    'bookingTerminalStatus'?: string;
    'userRegisterNext'?: boolean;
    'autoRegisterNext'?: boolean;
    'missingFields'?: Array<BookingMissingFieldsEnum>;
    'customerId'?: string;
    'customerName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "bookingId",
            "baseName": "bookingId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "relatedBookingsIdentifier",
            "baseName": "relatedBookingsIdentifier",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "BookingTypeEnum",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string",
            "format": ""
        },
        {
            "name": "forwarderName",
            "baseName": "forwarderName",
            "type": "string",
            "format": ""
        },
        {
            "name": "module",
            "baseName": "module",
            "type": "string",
            "format": ""
        },
        {
            "name": "parkingLot",
            "baseName": "parkingLot",
            "type": "string",
            "format": ""
        },
        {
            "name": "bookingState",
            "baseName": "bookingState",
            "type": "BookingBookingStateEnum",
            "format": ""
        },
        {
            "name": "containerUpdatable",
            "baseName": "containerUpdatable",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "cancelledBy",
            "baseName": "cancelledBy",
            "type": "string",
            "format": ""
        },
        {
            "name": "counterReason",
            "baseName": "counterReason",
            "type": "BookingCounterReasonEnum",
            "format": ""
        },
        {
            "name": "terminalId",
            "baseName": "terminalId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "terminalName",
            "baseName": "terminalName",
            "type": "string",
            "format": ""
        },
        {
            "name": "facilityId",
            "baseName": "facilityId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "facilityName",
            "baseName": "facilityName",
            "type": "string",
            "format": ""
        },
        {
            "name": "facilityType",
            "baseName": "facilityType",
            "type": "BookingFacilityTypeEnum",
            "format": ""
        },
        {
            "name": "container",
            "baseName": "container",
            "type": "Container",
            "format": ""
        },
        {
            "name": "timeSlot",
            "baseName": "timeSlot",
            "type": "TimeSlot",
            "format": ""
        },
        {
            "name": "bookingTerminalStatus",
            "baseName": "bookingTerminalStatus",
            "type": "string",
            "format": ""
        },
        {
            "name": "userRegisterNext",
            "baseName": "userRegisterNext",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "autoRegisterNext",
            "baseName": "autoRegisterNext",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "missingFields",
            "baseName": "missingFields",
            "type": "Array<BookingMissingFieldsEnum>",
            "format": ""
        },
        {
            "name": "customerId",
            "baseName": "customerId",
            "type": "string",
            "format": ""
        },
        {
            "name": "customerName",
            "baseName": "customerName",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Booking.attributeTypeMap;
    }

    public constructor() {
    }
}


export type BookingTypeEnum = "pickup" | "delivery" ;
export type BookingBookingStateEnum = "active" | "terminal_reached" | "registration_blocked" | "registration_restricted" | "registered" | "transhipment" | "completed" | "cancelled" | "counter_required" ;
export type BookingCounterReasonEnum = "dangerous_goods" | "toll" | "waste" | "not_delivered_yet" | "blocked" | "container_data_incomplete" | "module_closed" | "error" | "error_on_update_load_unit" | "loading_unit_unknown" ;
export type BookingFacilityTypeEnum = "TERMINAL" | "ISPS_TERMINAL" | "DEPOT" ;
export type BookingMissingFieldsEnum = "net_weight" | "seals" | "container_number" ;

