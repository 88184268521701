<!-- eslint-disable prettier/prettier -->
<template>
  <div class="flex justify-center">
    <div
      class="container-fluid p-8 border border-l-8 border-[#0b295b] shadow-xl rounded-lg bg-gray-100"
    >
      <div
        class="flex flex-col justify-center md:flex-row md:space-x-2 space-y-2 md:space-y-0"
      >
        <TextInput
          v-model="userId"
          placeholder="User Id"
          :wide="true"
        ></TextInput>
        <div>
          <DropDown
            :selected-value="terrorState"
            title="Terror state"
            @select="(t)=> terrorState = t as TruckerIdentificationTOTerrorStatusEnum"
            :entries="terrorStates"
          ></DropDown>
        </div>
        <div>
          <DropDown
            :selected-value="warningState"
            @select="(t)=> warningState = t as TruckerIdentificationTODocumentStatusEnum"
            title="Identification State"
            :entries="idStates"
          ></DropDown>
        </div>

        <div>
          <button
            @click="() => $emit('submit', payload)"
            class="btn btn-info w-32"
          >
            Search
          </button>
        </div>

        <div>
          <button @click="() => clearData()" class="btn btn-warning w-32">
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DropDown from "@/components/DropDown.vue";
import TextInput from "@/components/TextInput.vue";
import { terrorStates, idStates } from "./commons";
import {
  TruckerIdentificationTODocumentStatusEnum,
  TruckerIdentificationTOTerrorStatusEnum,
} from "@/services/api-client/client";

import { ref, computed, defineEmits } from "vue";

const userId = ref<string | undefined>(undefined);

const terrorState = ref<TruckerIdentificationTOTerrorStatusEnum | undefined>(
  undefined
);

const warningState = ref<TruckerIdentificationTODocumentStatusEnum | undefined>(
  undefined
);

const emit = defineEmits<{
  submit: [
    payload: {
      userId: string | undefined;
      warningState: TruckerIdentificationTODocumentStatusEnum | undefined;
      terrorState: TruckerIdentificationTOTerrorStatusEnum | undefined;
    }
  ];
}>();

const clearData = () => {
  userId.value = undefined;
  warningState.value = undefined;
  terrorState.value = undefined;
  emit("submit", payload.value);
};

const payload = computed(() => {
  return {
    userId: userId.value,
    warningState: warningState.value,
    terrorState: terrorState.value,
  };
});
</script>
