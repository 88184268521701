/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DangerousGoodsData } from '../models/DangerousGoodsData';
import { SealData } from '../models/SealData';
import { TollData } from '../models/TollData';
import { WasteData } from '../models/WasteData';
import { HttpFile } from '../http/http';

/**
* container / loading unit
*/
export class Container {
    'id'?: number;
    'containerNumber'?: string;
    'isoCode'?: string;
    'suggestedContainerNumber'?: string;
    'emptyWeight'?: number;
    'netWeight'?: number;
    'containerType'?: string;
    'containerLength'?: number;
    'hasDangerousGoods'?: boolean;
    'listDangerousGoodsData'?: Array<DangerousGoodsData>;
    'hasSeal'?: boolean;
    'listSealData'?: Array<SealData>;
    'hasToll'?: boolean;
    'listTollData'?: Array<TollData>;
    'hasWaste'?: boolean;
    'listWasteData'?: Array<WasteData>;
    'overhang'?: number;
    'teu'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "containerNumber",
            "baseName": "containerNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "isoCode",
            "baseName": "isoCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "suggestedContainerNumber",
            "baseName": "suggestedContainerNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "emptyWeight",
            "baseName": "emptyWeight",
            "type": "number",
            "format": "double"
        },
        {
            "name": "netWeight",
            "baseName": "netWeight",
            "type": "number",
            "format": "double"
        },
        {
            "name": "containerType",
            "baseName": "containerType",
            "type": "string",
            "format": ""
        },
        {
            "name": "containerLength",
            "baseName": "containerLength",
            "type": "number",
            "format": "double"
        },
        {
            "name": "hasDangerousGoods",
            "baseName": "hasDangerousGoods",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "listDangerousGoodsData",
            "baseName": "listDangerousGoodsData",
            "type": "Array<DangerousGoodsData>",
            "format": ""
        },
        {
            "name": "hasSeal",
            "baseName": "hasSeal",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "listSealData",
            "baseName": "listSealData",
            "type": "Array<SealData>",
            "format": ""
        },
        {
            "name": "hasToll",
            "baseName": "hasToll",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "listTollData",
            "baseName": "listTollData",
            "type": "Array<TollData>",
            "format": ""
        },
        {
            "name": "hasWaste",
            "baseName": "hasWaste",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "listWasteData",
            "baseName": "listWasteData",
            "type": "Array<WasteData>",
            "format": ""
        },
        {
            "name": "overhang",
            "baseName": "overhang",
            "type": "number",
            "format": "double"
        },
        {
            "name": "teu",
            "baseName": "teu",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return Container.attributeTypeMap;
    }

    public constructor() {
    }
}

