/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* acceptance deadline to be created
*/
export class OneToOneRestriction {
    'id'?: number;
    'restrictionStart': Date;
    'restrictionEnd': Date;
    'terminalId': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "restrictionStart",
            "baseName": "restrictionStart",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "restrictionEnd",
            "baseName": "restrictionEnd",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "terminalId",
            "baseName": "terminalId",
            "type": "number",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return OneToOneRestriction.attributeTypeMap;
    }

    public constructor() {
    }
}

