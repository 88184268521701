import { WebappUserRolesEnum } from "@/services/api-client/client";

const conrooRoles: WebappUserRolesEnum[] = [
  "conroo_admin",
  "conroo_read",
  "conroo_write",
];

const hasCurrentRole = (
  role: WebappUserRolesEnum,
  currentUserRoles: WebappUserRolesEnum[]
) => {
  return currentUserRoles.includes(role);
};

export const hasWritePermissions = (currentUserRoles: WebappUserRolesEnum[]) =>
  hasCurrentRole("conroo_write", currentUserRoles);

export const isAdmin = (currentUserRoles: WebappUserRolesEnum[]) =>
  hasCurrentRole("conroo_admin", currentUserRoles);

export const isConrooUser = (currentUserRoles: WebappUserRolesEnum[]) =>
  conrooRoles.some((e) => currentUserRoles.includes(e));
