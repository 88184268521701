export * from '../models/AcceptanceDeadline';
export * from '../models/AppVersionResponse';
export * from '../models/Booking';
export * from '../models/BookingForForwarderTO';
export * from '../models/BookingRequest';
export * from '../models/BookingsFilterRequestTO';
export * from '../models/ColorBorder';
export * from '../models/ConfirmBookingEntries';
export * from '../models/ConstantForwarderStatisticFloatValueResponseTO';
export * from '../models/ConstantForwarderStatisticValueRequestTO';
export * from '../models/Container';
export * from '../models/ContainerBookingCreationRequestTO';
export * from '../models/CreateForwarderUserTO';
export * from '../models/CreateTerminalOperatorUserTO';
export * from '../models/CreateTrainOperatorUserTO';
export * from '../models/CustomerTO';
export * from '../models/DangerousGoodsData';
export * from '../models/DeliveryTimes';
export * from '../models/DepotContainerBookingTO';
export * from '../models/DepotGetStatusMultiRequestTO';
export * from '../models/DepotGetStatusMultiResponseTO';
export * from '../models/DepotGetStatusRequestTO';
export * from '../models/DepotGetStatusResponseTO';
export * from '../models/ErrorResponse';
export * from '../models/Forwarder';
export * from '../models/ForwarderApproval';
export * from '../models/ForwarderUserTO';
export * from '../models/GetStatusDownloadCSVRequestTO';
export * from '../models/GetStatusRequest';
export * from '../models/GetStatusRequestResponseTO';
export * from '../models/GetStatusResponse';
export * from '../models/IspsChangeApprovalRequestTO';
export * from '../models/IspsLicenseKeyResponseTO';
export * from '../models/IspsLicenseTO';
export * from '../models/IspsTruckerChangeRequestTO';
export * from '../models/IspsTruckerChangeTO';
export * from '../models/IspsTruckerTO';
export * from '../models/LocalTime';
export * from '../models/OneToOneRestriction';
export * from '../models/ParseGetStatusFileResponse';
export * from '../models/PickupTimes';
export * from '../models/RangedTerminalStatisticsValueRequest';
export * from '../models/RangedTerminalStatisticsValueResponse';
export * from '../models/ResetPassword';
export * from '../models/SealData';
export * from '../models/Terminal';
export * from '../models/TerminalModule';
export * from '../models/TerminalOpeningHour';
export * from '../models/TerminalOperatorUserTO';
export * from '../models/TerminalReduced';
export * from '../models/TimeSeriesRequest';
export * from '../models/TimeSeriesResponse';
export * from '../models/TimeSlot';
export * from '../models/TollData';
export * from '../models/TourCreationRequestTO';
export * from '../models/TourTO';
export * from '../models/Train';
export * from '../models/TrainLocationTO';
export * from '../models/TrainOperator';
export * from '../models/TrainOperatorBooking';
export * from '../models/TrainOperatorUserTO';
export * from '../models/TrainOperatorUserTOLanguage';
export * from '../models/TruckerBlocklistRequestTO';
export * from '../models/TruckerBlocklistResponseTO';
export * from '../models/TruckerForForwarderTO';
export * from '../models/TruckerIdentificationTO';
export * from '../models/TruckerInformationTO';
export * from '../models/UpdateTruckerForForwarderTO';
export * from '../models/User';
export * from '../models/VerifyEmail';
export * from '../models/WasteData';
export * from '../models/WebappUser';

import { AcceptanceDeadline } from '../models/AcceptanceDeadline';
import { AppVersionResponse } from '../models/AppVersionResponse';
import { Booking   , BookingTypeEnum      , BookingBookingStateEnum    , BookingCounterReasonEnum      , BookingFacilityTypeEnum       , BookingMissingFieldsEnum     } from '../models/Booking';
import { BookingForForwarderTO , BookingForForwarderTOStatusEnum  , BookingForForwarderTOTypeEnum          } from '../models/BookingForForwarderTO';
import { BookingRequest    , BookingRequestTypeEnum      } from '../models/BookingRequest';
import { BookingsFilterRequestTO , BookingsFilterRequestTOBookingStateEnum  , BookingsFilterRequestTOBookingTypeEnum         } from '../models/BookingsFilterRequestTO';
import { ColorBorder  , ColorBorderColorEnum   } from '../models/ColorBorder';
import { ConfirmBookingEntries } from '../models/ConfirmBookingEntries';
import { ConstantForwarderStatisticFloatValueResponseTO   , ConstantForwarderStatisticFloatValueResponseTONameEnum   } from '../models/ConstantForwarderStatisticFloatValueResponseTO';
import { ConstantForwarderStatisticValueRequestTO, ConstantForwarderStatisticValueRequestTONameEnum   } from '../models/ConstantForwarderStatisticValueRequestTO';
import { Container } from '../models/Container';
import { ContainerBookingCreationRequestTO   , ContainerBookingCreationRequestTOTypeEnum     } from '../models/ContainerBookingCreationRequestTO';
import { CreateForwarderUserTO     , CreateForwarderUserTOForwarderUserRoleEnum    } from '../models/CreateForwarderUserTO';
import { CreateTerminalOperatorUserTO     , CreateTerminalOperatorUserTOTerminalOperatorUserRoleEnum   } from '../models/CreateTerminalOperatorUserTO';
import { CreateTrainOperatorUserTO      , CreateTrainOperatorUserTOTrainOperatorUserRoleTOEnum   } from '../models/CreateTrainOperatorUserTO';
import { CustomerTO } from '../models/CustomerTO';
import { DangerousGoodsData    , DangerousGoodsDataAddedByEnum   } from '../models/DangerousGoodsData';
import { DeliveryTimes } from '../models/DeliveryTimes';
import { DepotContainerBookingTO     , DepotContainerBookingTOTypeEnum    } from '../models/DepotContainerBookingTO';
import { DepotGetStatusMultiRequestTO } from '../models/DepotGetStatusMultiRequestTO';
import { DepotGetStatusMultiResponseTO } from '../models/DepotGetStatusMultiResponseTO';
import { DepotGetStatusRequestTO   , DepotGetStatusRequestTOTypeEnum    } from '../models/DepotGetStatusRequestTO';
import { DepotGetStatusResponseTO      , DepotGetStatusResponseTOErrorCodeEnum   } from '../models/DepotGetStatusResponseTO';
import { ErrorResponse } from '../models/ErrorResponse';
import { Forwarder } from '../models/Forwarder';
import { ForwarderApproval } from '../models/ForwarderApproval';
import { ForwarderUserTO     , ForwarderUserTOForwarderUserRoleEnum    } from '../models/ForwarderUserTO';
import { GetStatusDownloadCSVRequestTO } from '../models/GetStatusDownloadCSVRequestTO';
import { GetStatusRequest  , GetStatusRequestTypeEnum    } from '../models/GetStatusRequest';
import { GetStatusRequestResponseTO } from '../models/GetStatusRequestResponseTO';
import { GetStatusResponse } from '../models/GetStatusResponse';
import { IspsChangeApprovalRequestTO } from '../models/IspsChangeApprovalRequestTO';
import { IspsLicenseKeyResponseTO } from '../models/IspsLicenseKeyResponseTO';
import { IspsLicenseTO } from '../models/IspsLicenseTO';
import { IspsTruckerChangeRequestTO  , IspsTruckerChangeRequestTODocumentStatusEnum  , IspsTruckerChangeRequestTOTerrorStatusEnum   } from '../models/IspsTruckerChangeRequestTO';
import { IspsTruckerChangeTO   , IspsTruckerChangeTODocumentStatusEnum  , IspsTruckerChangeTOTerrorStatusEnum  , IspsTruckerChangeTOStateEnum      } from '../models/IspsTruckerChangeTO';
import { IspsTruckerTO } from '../models/IspsTruckerTO';
import { LocalTime } from '../models/LocalTime';
import { OneToOneRestriction } from '../models/OneToOneRestriction';
import { ParseGetStatusFileResponse , ParseGetStatusFileResponseMissingColumnsEnum   , ParseGetStatusFileResponseErrorTypeEnum   } from '../models/ParseGetStatusFileResponse';
import { PickupTimes } from '../models/PickupTimes';
import { RangedTerminalStatisticsValueRequest  , RangedTerminalStatisticsValueRequestNameEnum   } from '../models/RangedTerminalStatisticsValueRequest';
import { RangedTerminalStatisticsValueResponse     , RangedTerminalStatisticsValueResponseNameEnum   } from '../models/RangedTerminalStatisticsValueResponse';
import { ResetPassword } from '../models/ResetPassword';
import { SealData  , SealDataAddedByEnum   } from '../models/SealData';
import { Terminal } from '../models/Terminal';
import { TerminalModule } from '../models/TerminalModule';
import { TerminalOpeningHour   , TerminalOpeningHourOpeningHourDayEnum  , TerminalOpeningHourOpeningHourTypeEnum   } from '../models/TerminalOpeningHour';
import { TerminalOperatorUserTO     , TerminalOperatorUserTOTerminalOperatorUserRoleEnum   } from '../models/TerminalOperatorUserTO';
import { TerminalReduced } from '../models/TerminalReduced';
import { TimeSeriesRequest, TimeSeriesRequestNameEnum    , TimeSeriesRequestDurationEnum   } from '../models/TimeSeriesRequest';
import { TimeSeriesResponse, TimeSeriesResponseNameEnum         } from '../models/TimeSeriesResponse';
import { TimeSlot   , TimeSlotCategoryEnum   } from '../models/TimeSlot';
import { TollData   , TollDataAddedByEnum   } from '../models/TollData';
import { TourCreationRequestTO } from '../models/TourCreationRequestTO';
import { TourTO } from '../models/TourTO';
import { Train         , TrainTrainTypeEnum   } from '../models/Train';
import { TrainLocationTO } from '../models/TrainLocationTO';
import { TrainOperator } from '../models/TrainOperator';
import { TrainOperatorBooking , TrainOperatorBookingTypeEnum                  } from '../models/TrainOperatorBooking';
import { TrainOperatorUserTO      , TrainOperatorUserTOTrainOperatorUserRoleTOEnum   } from '../models/TrainOperatorUserTO';
import { TrainOperatorUserTOLanguage } from '../models/TrainOperatorUserTOLanguage';
import { TruckerBlocklistRequestTO } from '../models/TruckerBlocklistRequestTO';
import { TruckerBlocklistResponseTO } from '../models/TruckerBlocklistResponseTO';
import { TruckerForForwarderTO } from '../models/TruckerForForwarderTO';
import { TruckerIdentificationTO      , TruckerIdentificationTOTerrorStatusEnum  , TruckerIdentificationTODocumentStatusEnum     } from '../models/TruckerIdentificationTO';
import { TruckerInformationTO } from '../models/TruckerInformationTO';
import { UpdateTruckerForForwarderTO } from '../models/UpdateTruckerForForwarderTO';
import { User           , UserIdentityVerifiedEnum  , UserDeviceVerifiedEnum   } from '../models/User';
import { VerifyEmail } from '../models/VerifyEmail';
import { WasteData    , WasteDataAddedByEnum   } from '../models/WasteData';
import { WebappUser     , WebappUserRolesEnum   } from '../models/WebappUser';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

const supportedMediaTypes: { [mediaType: string]: number } = {
  "application/json": Infinity,
  "application/octet-stream": 0,
  "application/x-www-form-urlencoded": 0
}


let enumsMap: Set<string> = new Set<string>([
    "BookingTypeEnum",
    "BookingBookingStateEnum",
    "BookingCounterReasonEnum",
    "BookingFacilityTypeEnum",
    "BookingMissingFieldsEnum",
    "BookingForForwarderTOStatusEnum",
    "BookingForForwarderTOTypeEnum",
    "BookingRequestTypeEnum",
    "BookingsFilterRequestTOBookingStateEnum",
    "BookingsFilterRequestTOBookingTypeEnum",
    "ColorBorderColorEnum",
    "ConstantForwarderStatisticFloatValueResponseTONameEnum",
    "ConstantForwarderStatisticValueRequestTONameEnum",
    "ContainerBookingCreationRequestTOTypeEnum",
    "CreateForwarderUserTOForwarderUserRoleEnum",
    "CreateTerminalOperatorUserTOTerminalOperatorUserRoleEnum",
    "CreateTrainOperatorUserTOTrainOperatorUserRoleTOEnum",
    "DangerousGoodsDataAddedByEnum",
    "DepotContainerBookingTOTypeEnum",
    "DepotGetStatusRequestTOTypeEnum",
    "DepotGetStatusResponseTOErrorCodeEnum",
    "ForwarderUserTOForwarderUserRoleEnum",
    "GetStatusRequestTypeEnum",
    "IspsTruckerChangeRequestTODocumentStatusEnum",
    "IspsTruckerChangeRequestTOTerrorStatusEnum",
    "IspsTruckerChangeTODocumentStatusEnum",
    "IspsTruckerChangeTOTerrorStatusEnum",
    "IspsTruckerChangeTOStateEnum",
    "ParseGetStatusFileResponseMissingColumnsEnum",
    "ParseGetStatusFileResponseErrorTypeEnum",
    "RangedTerminalStatisticsValueRequestNameEnum",
    "RangedTerminalStatisticsValueResponseNameEnum",
    "SealDataAddedByEnum",
    "TerminalOpeningHourOpeningHourDayEnum",
    "TerminalOpeningHourOpeningHourTypeEnum",
    "TerminalOperatorUserTOTerminalOperatorUserRoleEnum",
    "TimeSeriesRequestNameEnum",
    "TimeSeriesRequestDurationEnum",
    "TimeSeriesResponseNameEnum",
    "TimeSlotCategoryEnum",
    "TollDataAddedByEnum",
    "TrainTrainTypeEnum",
    "TrainOperatorBookingTypeEnum",
    "TrainOperatorUserTOTrainOperatorUserRoleTOEnum",
    "TruckerIdentificationTOTerrorStatusEnum",
    "TruckerIdentificationTODocumentStatusEnum",
    "UserIdentityVerifiedEnum",
    "UserDeviceVerifiedEnum",
    "WasteDataAddedByEnum",
    "WebappUserRolesEnum",
]);

let typeMap: {[index: string]: any} = {
    "AcceptanceDeadline": AcceptanceDeadline,
    "AppVersionResponse": AppVersionResponse,
    "Booking": Booking,
    "BookingForForwarderTO": BookingForForwarderTO,
    "BookingRequest": BookingRequest,
    "BookingsFilterRequestTO": BookingsFilterRequestTO,
    "ColorBorder": ColorBorder,
    "ConfirmBookingEntries": ConfirmBookingEntries,
    "ConstantForwarderStatisticFloatValueResponseTO": ConstantForwarderStatisticFloatValueResponseTO,
    "ConstantForwarderStatisticValueRequestTO": ConstantForwarderStatisticValueRequestTO,
    "Container": Container,
    "ContainerBookingCreationRequestTO": ContainerBookingCreationRequestTO,
    "CreateForwarderUserTO": CreateForwarderUserTO,
    "CreateTerminalOperatorUserTO": CreateTerminalOperatorUserTO,
    "CreateTrainOperatorUserTO": CreateTrainOperatorUserTO,
    "CustomerTO": CustomerTO,
    "DangerousGoodsData": DangerousGoodsData,
    "DeliveryTimes": DeliveryTimes,
    "DepotContainerBookingTO": DepotContainerBookingTO,
    "DepotGetStatusMultiRequestTO": DepotGetStatusMultiRequestTO,
    "DepotGetStatusMultiResponseTO": DepotGetStatusMultiResponseTO,
    "DepotGetStatusRequestTO": DepotGetStatusRequestTO,
    "DepotGetStatusResponseTO": DepotGetStatusResponseTO,
    "ErrorResponse": ErrorResponse,
    "Forwarder": Forwarder,
    "ForwarderApproval": ForwarderApproval,
    "ForwarderUserTO": ForwarderUserTO,
    "GetStatusDownloadCSVRequestTO": GetStatusDownloadCSVRequestTO,
    "GetStatusRequest": GetStatusRequest,
    "GetStatusRequestResponseTO": GetStatusRequestResponseTO,
    "GetStatusResponse": GetStatusResponse,
    "IspsChangeApprovalRequestTO": IspsChangeApprovalRequestTO,
    "IspsLicenseKeyResponseTO": IspsLicenseKeyResponseTO,
    "IspsLicenseTO": IspsLicenseTO,
    "IspsTruckerChangeRequestTO": IspsTruckerChangeRequestTO,
    "IspsTruckerChangeTO": IspsTruckerChangeTO,
    "IspsTruckerTO": IspsTruckerTO,
    "LocalTime": LocalTime,
    "OneToOneRestriction": OneToOneRestriction,
    "ParseGetStatusFileResponse": ParseGetStatusFileResponse,
    "PickupTimes": PickupTimes,
    "RangedTerminalStatisticsValueRequest": RangedTerminalStatisticsValueRequest,
    "RangedTerminalStatisticsValueResponse": RangedTerminalStatisticsValueResponse,
    "ResetPassword": ResetPassword,
    "SealData": SealData,
    "Terminal": Terminal,
    "TerminalModule": TerminalModule,
    "TerminalOpeningHour": TerminalOpeningHour,
    "TerminalOperatorUserTO": TerminalOperatorUserTO,
    "TerminalReduced": TerminalReduced,
    "TimeSeriesRequest": TimeSeriesRequest,
    "TimeSeriesResponse": TimeSeriesResponse,
    "TimeSlot": TimeSlot,
    "TollData": TollData,
    "TourCreationRequestTO": TourCreationRequestTO,
    "TourTO": TourTO,
    "Train": Train,
    "TrainLocationTO": TrainLocationTO,
    "TrainOperator": TrainOperator,
    "TrainOperatorBooking": TrainOperatorBooking,
    "TrainOperatorUserTO": TrainOperatorUserTO,
    "TrainOperatorUserTOLanguage": TrainOperatorUserTOLanguage,
    "TruckerBlocklistRequestTO": TruckerBlocklistRequestTO,
    "TruckerBlocklistResponseTO": TruckerBlocklistResponseTO,
    "TruckerForForwarderTO": TruckerForForwarderTO,
    "TruckerIdentificationTO": TruckerIdentificationTO,
    "TruckerInformationTO": TruckerInformationTO,
    "UpdateTruckerForForwarderTO": UpdateTruckerForForwarderTO,
    "User": User,
    "VerifyEmail": VerifyEmail,
    "WasteData": WasteData,
    "WebappUser": WebappUser,
}

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let date of data) {
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }

            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let attributeType of attributeTypes) {
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let date of data) {
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let attributeType of attributeTypes) {
                let value = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
                if (value !== undefined) {
                    instance[attributeType.name] = value;
                }
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType: string | undefined = undefined;
        let selectedRank: number = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType!] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType!];
            }
        }

        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }

        return selectedMediaType!;
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (mediaType === "text/plain") {
            return String(data);
        }

        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (mediaType === "text/plain") {
            return rawData;
        }

        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }

        if (mediaType === "text/html") {
            return rawData;
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
