/* eslint-disable prettier/prettier */
type User = {
  userId: string;
  email: string;
};

import { initializeApp } from "firebase/app";
import { UserCredential, getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBWv-_1VTu-EuZRlnVRda4VxiGmmiZ14no",
  authDomain: "truckonia-f976d.firebaseapp.com",
  databaseURL: "https://truckonia-f976d.firebaseio.com",
  projectId: "truckonia-f976d",
  storageBucket: "truckonia-f976d.appspot.com",
  messagingSenderId: "374701058527",
  appId: "1:374701058527:web:ce4e66bb37f8294746fc6a",
  measurementId: "G-2215QZ9BQC"
};

const app = initializeApp(firebaseConfig);


export interface Authenticator {
  login(email: string, password: string): Promise<User>;
  logout(): Promise<void>;
  getCurrentUser() : User | null;
  getAccessToken(): Promise<string | undefined>;
}


export const getAuthService = () : Authenticator => {
  const firebaseAuth =  getAuth(app);
  return {
    login : async (email : string,password:string) => {
    const result  = await signInWithEmailAndPassword(firebaseAuth,email,password);

    return {
      userId:result.user.uid,
      email,
    }
    },
    logout: async () => {
      await signOut(firebaseAuth);
    },
    getCurrentUser:  () => {
     const currentUser =  firebaseAuth.currentUser;
      if(currentUser === null) {
        return null;
      }

      return {
        email:currentUser.email ?? "",
        userId:currentUser.uid
      };

    },
    getAccessToken: async () => {
      await firebaseAuth.authStateReady();
      const currentUser = firebaseAuth.currentUser;
      return await currentUser?.getIdToken();
    }
  }
}
