/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Color Border
*/
export class ColorBorder {
    'minValue': number;
    'maxValue': number;
    /**
    * Statistic Color
    */
    'color': ColorBorderColorEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "minValue",
            "baseName": "minValue",
            "type": "number",
            "format": "float"
        },
        {
            "name": "maxValue",
            "baseName": "maxValue",
            "type": "number",
            "format": "float"
        },
        {
            "name": "color",
            "baseName": "color",
            "type": "ColorBorderColorEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ColorBorder.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ColorBorderColorEnum = "green" | "yellow" | "red" | "blue" | "purple" ;

