/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Forwarder } from '../models/Forwarder';
import { IspsLicenseTO } from '../models/IspsLicenseTO';
import { IspsTruckerChangeTO } from '../models/IspsTruckerChangeTO';
import { TruckerIdentificationTO } from '../models/TruckerIdentificationTO';
import { HttpFile } from '../http/http';

export class IspsTruckerTO {
    'userId'?: string;
    'email'?: string;
    'locale'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'phoneNumber'?: string;
    'loginType'?: string;
    'forwarder'?: Forwarder;
    'otherForwarder'?: string;
    'licensePlate'?: string;
    'ispsLicense'?: IspsLicenseTO;
    'truckerIdentification'?: Array<TruckerIdentificationTO>;
    'changes'?: Array<IspsTruckerChangeTO>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "locale",
            "baseName": "locale",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "phoneNumber",
            "baseName": "phoneNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "loginType",
            "baseName": "loginType",
            "type": "string",
            "format": ""
        },
        {
            "name": "forwarder",
            "baseName": "forwarder",
            "type": "Forwarder",
            "format": ""
        },
        {
            "name": "otherForwarder",
            "baseName": "otherForwarder",
            "type": "string",
            "format": ""
        },
        {
            "name": "licensePlate",
            "baseName": "licensePlate",
            "type": "string",
            "format": ""
        },
        {
            "name": "ispsLicense",
            "baseName": "ispsLicense",
            "type": "IspsLicenseTO",
            "format": ""
        },
        {
            "name": "truckerIdentification",
            "baseName": "truckerIdentification",
            "type": "Array<TruckerIdentificationTO>",
            "format": ""
        },
        {
            "name": "changes",
            "baseName": "changes",
            "type": "Array<IspsTruckerChangeTO>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return IspsTruckerTO.attributeTypeMap;
    }

    public constructor() {
    }
}

