<template>
  <dialog id="my_modal_2" class="rounded">
    <div class="flex flex-col justify-center p-4">
      <div class="flex flex-row justify-center space-x-2">
        <div class="text-xl md:text-2xl text-center">All changes to user:</div>
        <button @click="() => closeDialog()" class="btn btn-info btn-sm mr-2">
          Close dialog
        </button>
      </div>

      <div class="mt-4">
        <HistoryTable
          @approve="(t) => sendApproval(t)"
          :users="props.users"
        ></HistoryTable>
      </div>
    </div>
  </dialog>
</template>

<script lang="ts" setup>
import { IspsTruckerChangeTO } from "@/services/api-client/client";
import HistoryTable from "./HistoryTable.vue";
import { defineProps, watch, defineEmits } from "vue";

const props = defineProps<{
  users?: IspsTruckerChangeTO[];
  openDialog: boolean;
}>();

const emit = defineEmits<{
  approve: [payload: { approve: boolean; id: number }];
}>();

watch(
  () => props.openDialog,
  async (_) => {
    const dialog = document.getElementById("my_modal_2");
    (dialog as HTMLDialogElement)?.showModal();
  }
);

const sendApproval = (payload: { approve: boolean; id: number }) => {
  closeDialog();
  emit("approve", payload);
};

const closeDialog = () => {
  const dialog = document.getElementById("my_modal_2");
  (dialog as HTMLDialogElement)?.close();
};
</script>
