// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { ErrorResponse } from '../models/ErrorResponse';
import { IspsChangeApprovalRequestTO } from '../models/IspsChangeApprovalRequestTO';
import { IspsTruckerChangeRequestTO } from '../models/IspsTruckerChangeRequestTO';
import { IspsTruckerTO } from '../models/IspsTruckerTO';

/**
 * no description
 */
export class IspsAdminUsersApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Deletes TruckerIdentification tries and automatically approves
     * @param userId UUID that comes from firebase authentication
     * @param truckerId Trucker id that needs to be reset
     */
    public async deleteTruckerIdentifications(userId: string, truckerId: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "deleteTruckerIdentifications", "userId");
        }


        // verify required parameter 'truckerId' is not null or undefined
        if (truckerId === null || truckerId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "deleteTruckerIdentifications", "truckerId");
        }


        // Path Params
        const localVarPath = '/web/v1/ispsAdminUser/users/{userId}/truckers/{truckerId}/changes/reset'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
            .replace('{' + 'truckerId' + '}', encodeURIComponent(String(truckerId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * get for trucker in regards of identification and licensing
     * get Isps Trucker
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be retrieved
     * @param request 
     */
    public async getIspsTrucker(userId: string, truckerId: string, request: IspsTruckerTO, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "getIspsTrucker", "userId");
        }


        // verify required parameter 'truckerId' is not null or undefined
        if (truckerId === null || truckerId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "getIspsTrucker", "truckerId");
        }


        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "getIspsTrucker", "request");
        }


        // Path Params
        const localVarPath = '/web/v1/ispsAdminUser/users/{userId}/truckers/{truckerId}'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
            .replace('{' + 'truckerId' + '}', encodeURIComponent(String(truckerId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (request !== undefined) {
            requestContext.setQueryParam("request", ObjectSerializer.serialize(request, "IspsTruckerTO", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * get Isps Truckers
     * @param userId UUID that comes from firebase authentication
     * @param documentIdentificationStatus Document Identification Status
     * @param terrorDatabaseStatus Terror Database Status
     * @param truckerId Trucker ID
     * @param page Page number
     * @param size 
     */
    public async getIspsTruckers(userId: string, documentIdentificationStatus?: 'ACCEPTED' | 'PENDING' | 'DENIED' | 'WARN' | 'DENIED_PRELIMINARY' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED' | 'FAILED', terrorDatabaseStatus?: 'OK' | 'WARNING' | 'RED_ALERT' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED', truckerId?: string, page?: number, size?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "getIspsTruckers", "userId");
        }







        // Path Params
        const localVarPath = '/web/v1/ispsAdminUser/users/{userId}/truckers'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (documentIdentificationStatus !== undefined) {
            requestContext.setQueryParam("documentIdentificationStatus", ObjectSerializer.serialize(documentIdentificationStatus, "'ACCEPTED' | 'PENDING' | 'DENIED' | 'WARN' | 'DENIED_PRELIMINARY' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED' | 'FAILED'", ""));
        }

        // Query Params
        if (terrorDatabaseStatus !== undefined) {
            requestContext.setQueryParam("terrorDatabaseStatus", ObjectSerializer.serialize(terrorDatabaseStatus, "'OK' | 'WARNING' | 'RED_ALERT' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED'", ""));
        }

        // Query Params
        if (truckerId !== undefined) {
            requestContext.setQueryParam("truckerId", ObjectSerializer.serialize(truckerId, "string", ""));
        }

        // Query Params
        if (page !== undefined) {
            requestContext.setQueryParam("page", ObjectSerializer.serialize(page, "number", "int32"));
        }

        // Query Params
        if (size !== undefined) {
            requestContext.setQueryParam("size", ObjectSerializer.serialize(size, "number", "int32"));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * cerate for trucker in regards of identification and licensing
     * create isps trucker log
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be edited
     * @param ispsTruckerChangeRequestTO 
     */
    public async ispsTruckerLog(userId: string, truckerId: string, ispsTruckerChangeRequestTO: IspsTruckerChangeRequestTO, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "ispsTruckerLog", "userId");
        }


        // verify required parameter 'truckerId' is not null or undefined
        if (truckerId === null || truckerId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "ispsTruckerLog", "truckerId");
        }


        // verify required parameter 'ispsTruckerChangeRequestTO' is not null or undefined
        if (ispsTruckerChangeRequestTO === null || ispsTruckerChangeRequestTO === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "ispsTruckerLog", "ispsTruckerChangeRequestTO");
        }


        // Path Params
        const localVarPath = '/web/v1/ispsAdminUser/users/{userId}/truckers/{truckerId}/changes'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
            .replace('{' + 'truckerId' + '}', encodeURIComponent(String(truckerId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(ispsTruckerChangeRequestTO, "IspsTruckerChangeRequestTO", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * Patch Isps Trucker
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be approved
     * @param changeId Id of the change to be approved
     * @param ispsChangeApprovalRequestTO 
     */
    public async patchIspsTrucker(userId: string, truckerId: string, changeId: number, ispsChangeApprovalRequestTO: IspsChangeApprovalRequestTO, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "patchIspsTrucker", "userId");
        }


        // verify required parameter 'truckerId' is not null or undefined
        if (truckerId === null || truckerId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "patchIspsTrucker", "truckerId");
        }


        // verify required parameter 'changeId' is not null or undefined
        if (changeId === null || changeId === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "patchIspsTrucker", "changeId");
        }


        // verify required parameter 'ispsChangeApprovalRequestTO' is not null or undefined
        if (ispsChangeApprovalRequestTO === null || ispsChangeApprovalRequestTO === undefined) {
            throw new RequiredError("IspsAdminUsersApi", "patchIspsTrucker", "ispsChangeApprovalRequestTO");
        }


        // Path Params
        const localVarPath = '/web/v1/ispsAdminUser/users/{userId}/trucker/{truckerId}/changes/{changeId}'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
            .replace('{' + 'truckerId' + '}', encodeURIComponent(String(truckerId)))
            .replace('{' + 'changeId' + '}', encodeURIComponent(String(changeId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PUT);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(ispsChangeApprovalRequestTO, "IspsChangeApprovalRequestTO", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class IspsAdminUsersApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to deleteTruckerIdentifications
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async deleteTruckerIdentifications(response: ResponseContext): Promise<IspsTruckerTO > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: IspsTruckerTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "IspsTruckerTO", ""
            ) as IspsTruckerTO;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: IspsTruckerTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "IspsTruckerTO", ""
            ) as IspsTruckerTO;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getIspsTrucker
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getIspsTrucker(response: ResponseContext): Promise<IspsTruckerTO > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: IspsTruckerTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "IspsTruckerTO", ""
            ) as IspsTruckerTO;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: IspsTruckerTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "IspsTruckerTO", ""
            ) as IspsTruckerTO;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getIspsTruckers
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getIspsTruckers(response: ResponseContext): Promise<Array<IspsTruckerTO> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<IspsTruckerTO> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<IspsTruckerTO>", ""
            ) as Array<IspsTruckerTO>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<IspsTruckerTO> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<IspsTruckerTO>", ""
            ) as Array<IspsTruckerTO>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to ispsTruckerLog
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async ispsTruckerLog(response: ResponseContext): Promise<IspsTruckerTO > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: IspsTruckerTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "IspsTruckerTO", ""
            ) as IspsTruckerTO;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: IspsTruckerTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "IspsTruckerTO", ""
            ) as IspsTruckerTO;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to patchIspsTrucker
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async patchIspsTrucker(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
