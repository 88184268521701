/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class IspsTruckerChangeRequestTO {
    'firstName'?: string;
    'lastName'?: string;
    'documentStatus'?: IspsTruckerChangeRequestTODocumentStatusEnum;
    'terrorStatus'?: IspsTruckerChangeRequestTOTerrorStatusEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "documentStatus",
            "baseName": "documentStatus",
            "type": "IspsTruckerChangeRequestTODocumentStatusEnum",
            "format": ""
        },
        {
            "name": "terrorStatus",
            "baseName": "terrorStatus",
            "type": "IspsTruckerChangeRequestTOTerrorStatusEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return IspsTruckerChangeRequestTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type IspsTruckerChangeRequestTODocumentStatusEnum = "ACCEPTED" | "PENDING" | "DENIED" | "WARN" | "DENIED_PRELIMINARY" | "MANUALLY_APPROVED" | "MANUALLY_DENIED" | "FAILED" ;
export type IspsTruckerChangeRequestTOTerrorStatusEnum = "OK" | "WARNING" | "RED_ALERT" | "MANUALLY_APPROVED" | "MANUALLY_DENIED" ;

