/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Forwarder } from '../models/Forwarder';
import { HttpFile } from '../http/http';

export class TruckerBlocklistResponseTO {
    'id'?: number;
    'truckerId'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'facilityId'?: number;
    'forwarder'?: Forwarder;
    'imageUrl'?: string;
    'blockedReason'?: string;
    'blockedDate'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "truckerId",
            "baseName": "truckerId",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "facilityId",
            "baseName": "facilityId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "forwarder",
            "baseName": "forwarder",
            "type": "Forwarder",
            "format": ""
        },
        {
            "name": "imageUrl",
            "baseName": "imageUrl",
            "type": "string",
            "format": ""
        },
        {
            "name": "blockedReason",
            "baseName": "blockedReason",
            "type": "string",
            "format": ""
        },
        {
            "name": "blockedDate",
            "baseName": "blockedDate",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TruckerBlocklistResponseTO.attributeTypeMap;
    }

    public constructor() {
    }
}

