/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetStatusRequest } from '../models/GetStatusRequest';
import { HttpFile } from '../http/http';

/**
* Response for parseGetStatusFile request containing parsed requests and information about errors
*/
export class ParseGetStatusFileResponse {
    /**
    * parsed getStatusRequest objects from csv file, null on parsing errors
    */
    'getStatusRequests'?: Array<GetStatusRequest>;
    /**
    * if in header row column headers are missing, they are listed here
    */
    'missingColumns'?: Array<ParseGetStatusFileResponseMissingColumnsEnum>;
    /**
    * row in the csv where the error was found. -1 means csv header row
    */
    'corruptRow'?: number;
    /**
    * Errors that can happen when parsing a csv
    */
    'errorType'?: ParseGetStatusFileResponseErrorTypeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "getStatusRequests",
            "baseName": "getStatusRequests",
            "type": "Array<GetStatusRequest>",
            "format": ""
        },
        {
            "name": "missingColumns",
            "baseName": "missingColumns",
            "type": "Array<ParseGetStatusFileResponseMissingColumnsEnum>",
            "format": ""
        },
        {
            "name": "corruptRow",
            "baseName": "corruptRow",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "errorType",
            "baseName": "errorType",
            "type": "ParseGetStatusFileResponseErrorTypeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ParseGetStatusFileResponse.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ParseGetStatusFileResponseMissingColumnsEnum = "reference_number" | "terminal_id" | "container_number" | "type" ;
export type ParseGetStatusFileResponseErrorTypeEnum = "column_missing" | "row_incomplete" | "datatype_mismatch" | "booking_type_not_parseable" ;

