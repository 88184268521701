import { v4 } from "uuid";
import { ref } from "vue";

export type ToastLevel = "success" | "warning" | "error";

const TOAST_LIMIT = 3;
const TOAST_TIMEOUT_MILLISECONDS = 3000;

type Toast = {
  id: string;
  level: ToastLevel;
  message: string;
};

const storeData = ref<Toast[]>([]);

interface ToastStore {
  getToasts(): Toast[];
  addToast(params: { level: ToastLevel; message: string }): void;
}

export const useToastStore = (): ToastStore => {
  return {
    addToast: (toastInput: { level: ToastLevel; message: string }): void => {
      if (storeData.value.length >= TOAST_LIMIT) {
        return;
      }
      //assigning a new uuid since this calls
      //setTimeout(ergo concurrency functionality) and its easier to handle them if we dont have incrementing ids

      const uuid = v4();
      const newInput = { id: uuid, ...toastInput };
      storeData.value.push(newInput);
      setTimeout(() => {
        storeData.value = storeData.value.filter((e) => e.id !== uuid);
      }, TOAST_TIMEOUT_MILLISECONDS);
    },
    getToasts: (): Toast[] => {
      return storeData.value;
    },
  };
};
