/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Object which gives filtering parameters
*/
export class BookingsFilterRequestTO {
    'timeSlotId'?: number;
    /**
    * state of a Booking
    */
    'bookingState'?: BookingsFilterRequestTOBookingStateEnum;
    /**
    * Type of a Task
    */
    'bookingType'?: BookingsFilterRequestTOBookingTypeEnum;
    'licensePlate'?: string;
    'truckerId'?: string;
    'containerType'?: string;
    'terminalName'?: string;
    'containerNumber'?: string;
    'referenceNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "timeSlotId",
            "baseName": "timeSlotId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "bookingState",
            "baseName": "bookingState",
            "type": "BookingsFilterRequestTOBookingStateEnum",
            "format": ""
        },
        {
            "name": "bookingType",
            "baseName": "bookingType",
            "type": "BookingsFilterRequestTOBookingTypeEnum",
            "format": ""
        },
        {
            "name": "licensePlate",
            "baseName": "licensePlate",
            "type": "string",
            "format": ""
        },
        {
            "name": "truckerId",
            "baseName": "truckerId",
            "type": "string",
            "format": ""
        },
        {
            "name": "containerType",
            "baseName": "containerType",
            "type": "string",
            "format": ""
        },
        {
            "name": "terminalName",
            "baseName": "terminalName",
            "type": "string",
            "format": ""
        },
        {
            "name": "containerNumber",
            "baseName": "containerNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return BookingsFilterRequestTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type BookingsFilterRequestTOBookingStateEnum = "active" | "terminal_reached" | "registration_blocked" | "registration_restricted" | "registered" | "transhipment" | "completed" | "cancelled" | "counter_required" ;
export type BookingsFilterRequestTOBookingTypeEnum = "pickup" | "delivery" ;

