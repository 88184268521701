import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "@/views/login/LoginView.vue";
import UsersManagement from "@/views/users-management/UsersManagement.vue";
import firebase from "firebase/app";
import { getAuthService } from "@/services/auth/auth-service";
import { useUserStore } from "@/observables/store/user-store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: LoginView,
  },
  {
    path: "/usersManagement",
    name: "userManagement",
    component: UsersManagement,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const userStore = useUserStore();

  const loggedIn = userStore.getUser() != null;

  if (to.fullPath === "/" && loggedIn) {
    next({ name: "userManagement" });
  } else if (requiresAuth && !loggedIn) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
