/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ContainerBookingCreationRequestTO } from '../models/ContainerBookingCreationRequestTO';
import { TimeSlot } from '../models/TimeSlot';
import { HttpFile } from '../http/http';

export class TourCreationRequestTO {
    'tourId'?: number;
    'timeSlot': TimeSlot;
    'licensePlate': string;
    'forwarderName': string;
    'statusRequests': Array<ContainerBookingCreationRequestTO>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "tourId",
            "baseName": "tourId",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "timeSlot",
            "baseName": "timeSlot",
            "type": "TimeSlot",
            "format": ""
        },
        {
            "name": "licensePlate",
            "baseName": "licensePlate",
            "type": "string",
            "format": ""
        },
        {
            "name": "forwarderName",
            "baseName": "forwarderName",
            "type": "string",
            "format": ""
        },
        {
            "name": "statusRequests",
            "baseName": "statusRequests",
            "type": "Array<ContainerBookingCreationRequestTO>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TourCreationRequestTO.attributeTypeMap;
    }

    public constructor() {
    }
}

