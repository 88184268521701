/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TrainOperatorUserTOLanguage } from '../models/TrainOperatorUserTOLanguage';
import { HttpFile } from '../http/http';

/**
* Specific user to be added
*/
export class TerminalOperatorUserTO {
    'id'?: string;
    'language'?: TrainOperatorUserTOLanguage;
    'firstName': string;
    'lastName': string;
    'email': string;
    'terminalOperatorUserRole'?: TerminalOperatorUserTOTerminalOperatorUserRoleEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "language",
            "baseName": "language",
            "type": "TrainOperatorUserTOLanguage",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "terminalOperatorUserRole",
            "baseName": "terminalOperatorUserRole",
            "type": "TerminalOperatorUserTOTerminalOperatorUserRoleEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TerminalOperatorUserTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TerminalOperatorUserTOTerminalOperatorUserRoleEnum = "TERMINAL_OPERATIONS_READ" | "TERMINAL_OPERATIONS_WRITE" | "TERMINAL_OPERATIONS_ADMIN" ;

