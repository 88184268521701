<template>
  <!-- v-model check shit  -->
  <div class="dropdown w-full">
    <div tabindex="-1" class="btn">{{ valueToShow }}</div>
    <ul
      tabindex="-1"
      class="dropdown-content z-[1] menu shadow bg-base-100 rounded-box"
    >
      <div v-for="(item, index) in props.entries" :key="index">
        <button class="p-2" @click="() => emitSelect(item)">
          <li>{{ item }}</li>
        </button>
      </div>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, computed, defineEmits } from "vue";

const props = defineProps<{
  entries: string[];
  title: string;
  selectedValue: string | null | undefined;
}>();

const valueToShow = computed(() =>
  props.selectedValue == null ? props.title : props.selectedValue
);

const emit = defineEmits<{
  select: [value: string];
}>();

const emitSelect = (item: string): void => {
  emit("select", item);
};
</script>
