/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { WebappUser } from '../models/WebappUser';
import { HttpFile } from '../http/http';

export class TruckerIdentificationTO {
    'identificationNumber'?: string;
    'issuer'?: string;
    'cisFileUid'?: string;
    'createDate'?: Date;
    'verifiedDate'?: Date;
    'dateOfBirth'?: Date;
    'terrorStatus'?: TruckerIdentificationTOTerrorStatusEnum;
    'documentStatus'?: TruckerIdentificationTODocumentStatusEnum;
    'manuallyApprovedBy'?: WebappUser;
    'manualApprovalSupervisor'?: WebappUser;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "identificationNumber",
            "baseName": "identificationNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "issuer",
            "baseName": "issuer",
            "type": "string",
            "format": ""
        },
        {
            "name": "cisFileUid",
            "baseName": "cisFileUid",
            "type": "string",
            "format": ""
        },
        {
            "name": "createDate",
            "baseName": "create_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "verifiedDate",
            "baseName": "verified_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dateOfBirth",
            "baseName": "dateOfBirth",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "terrorStatus",
            "baseName": "terrorStatus",
            "type": "TruckerIdentificationTOTerrorStatusEnum",
            "format": ""
        },
        {
            "name": "documentStatus",
            "baseName": "documentStatus",
            "type": "TruckerIdentificationTODocumentStatusEnum",
            "format": ""
        },
        {
            "name": "manuallyApprovedBy",
            "baseName": "manuallyApprovedBy",
            "type": "WebappUser",
            "format": ""
        },
        {
            "name": "manualApprovalSupervisor",
            "baseName": "manualApprovalSupervisor",
            "type": "WebappUser",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TruckerIdentificationTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TruckerIdentificationTOTerrorStatusEnum = "OK" | "WARNING" | "RED_ALERT" | "MANUALLY_APPROVED" | "MANUALLY_DENIED" ;
export type TruckerIdentificationTODocumentStatusEnum = "ACCEPTED" | "PENDING" | "DENIED" | "WARN" | "DENIED_PRELIMINARY" | "MANUALLY_APPROVED" | "MANUALLY_DENIED" | "FAILED" ;

