/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetStatusRequestResponseTO } from '../models/GetStatusRequestResponseTO';
import { HttpFile } from '../http/http';

/**
* Csv file containing container data to be parsed
*/
export class GetStatusDownloadCSVRequestTO {
    'getStatusRequestResponses'?: Array<GetStatusRequestResponseTO>;
    'terminalId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "getStatusRequestResponses",
            "baseName": "getStatusRequestResponses",
            "type": "Array<GetStatusRequestResponseTO>",
            "format": ""
        },
        {
            "name": "terminalId",
            "baseName": "terminalId",
            "type": "number",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return GetStatusDownloadCSVRequestTO.attributeTypeMap;
    }

    public constructor() {
    }
}

