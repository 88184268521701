/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TimeSlot } from '../models/TimeSlot';
import { TruckerInformationTO } from '../models/TruckerInformationTO';
import { HttpFile } from '../http/http';

export class BookingForForwarderTO {
    'id'?: number;
    /**
    * state of a Booking
    */
    'status'?: BookingForForwarderTOStatusEnum;
    /**
    * Type of a Task
    */
    'type'?: BookingForForwarderTOTypeEnum;
    'containerNumber'?: string;
    'referenceNumber'?: string;
    'containerType'?: string;
    'truckerInformation'?: TruckerInformationTO;
    'licensePlate'?: string;
    'terminalName'?: string;
    'timeslot'?: TimeSlot;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "BookingForForwarderTOStatusEnum",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "BookingForForwarderTOTypeEnum",
            "format": ""
        },
        {
            "name": "containerNumber",
            "baseName": "containerNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "containerType",
            "baseName": "containerType",
            "type": "string",
            "format": ""
        },
        {
            "name": "truckerInformation",
            "baseName": "truckerInformation",
            "type": "TruckerInformationTO",
            "format": ""
        },
        {
            "name": "licensePlate",
            "baseName": "licensePlate",
            "type": "string",
            "format": ""
        },
        {
            "name": "terminalName",
            "baseName": "terminalName",
            "type": "string",
            "format": ""
        },
        {
            "name": "timeslot",
            "baseName": "timeslot",
            "type": "TimeSlot",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return BookingForForwarderTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type BookingForForwarderTOStatusEnum = "active" | "terminal_reached" | "registration_blocked" | "registration_restricted" | "registered" | "transhipment" | "completed" | "cancelled" | "counter_required" ;
export type BookingForForwarderTOTypeEnum = "pickup" | "delivery" ;

