import { ref, onMounted, onUnmounted, watch } from "vue";

type FetchItemsCallback = (page: number, pageSize: number) => Promise<boolean>;

export const usePagination = (callback: FetchItemsCallback) => {
  const PAGE_SIZE = 20;
  const DEBOUNCE_DELAY = 200;
  const pageIndex = ref(0);
  const loading = ref(false);
  let fetchedAllItems = false;

  watch(
    () => pageIndex.value,
    (v) => {
      console.log(v);
    }
  );

  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const debounce = (func: (...args: unknown[]) => unknown, delay: number) => {
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(func, delay);
  };

  const listenToScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (fetchedAllItems || loading.value) {
        return;
      }
      loading.value = true;
      debounce(async () => {
        const fetchedAll = await callback(PAGE_SIZE, pageIndex.value);
        pageIndex.value++;
        loading.value = false;
        if (fetchedAll) {
          fetchedAllItems = true;
          return;
        }
      }, DEBOUNCE_DELAY);
    }
  };

  onMounted(() => {
    window.addEventListener("scroll", listenToScroll);
  });

  onUnmounted(() => window.removeEventListener("scroll", listenToScroll));

  return {
    loading,
    pageIndex,
    pageSize: PAGE_SIZE,
    restartPageIndex: () => {
      pageIndex.value = 0;
    },
    incrementPageIndex: () => {
      pageIndex.value++;
    },
  };
};
