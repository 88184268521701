/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Forwarder } from '../models/Forwarder';
import { IspsLicenseKeyResponseTO } from '../models/IspsLicenseKeyResponseTO';
import { HttpFile } from '../http/http';

/**
* CONROO user
*/
export class User {
    'userId'?: string;
    'email'?: string;
    'locale'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'phoneNumber'?: string;
    'forwarder': Forwarder;
    'otherForwarder'?: string;
    'licensePlate'?: string;
    'isAtTerminal'?: number;
    'ispsLicense'?: IspsLicenseKeyResponseTO;
    'identityVerified'?: UserIdentityVerifiedEnum;
    'deviceVerified'?: UserDeviceVerifiedEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "locale",
            "baseName": "locale",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "phoneNumber",
            "baseName": "phoneNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "forwarder",
            "baseName": "forwarder",
            "type": "Forwarder",
            "format": ""
        },
        {
            "name": "otherForwarder",
            "baseName": "otherForwarder",
            "type": "string",
            "format": ""
        },
        {
            "name": "licensePlate",
            "baseName": "licensePlate",
            "type": "string",
            "format": ""
        },
        {
            "name": "isAtTerminal",
            "baseName": "isAtTerminal",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "ispsLicense",
            "baseName": "ispsLicense",
            "type": "IspsLicenseKeyResponseTO",
            "format": ""
        },
        {
            "name": "identityVerified",
            "baseName": "identityVerified",
            "type": "UserIdentityVerifiedEnum",
            "format": ""
        },
        {
            "name": "deviceVerified",
            "baseName": "deviceVerified",
            "type": "UserDeviceVerifiedEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return User.attributeTypeMap;
    }

    public constructor() {
    }
}


export type UserIdentityVerifiedEnum = "VERIFIED" | "NOT_VERIFIED" | "PENDING" | "DENIED_PRELIMINARY" | "DENIED" ;
export type UserDeviceVerifiedEnum = "VERIFIED" | "NOT_VERIFIED" ;

