/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Terminal module
*/
export class TerminalModule {
    'id': string;
    'moduleName': string;
    'opened'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "moduleName",
            "baseName": "moduleName",
            "type": "string",
            "format": ""
        },
        {
            "name": "opened",
            "baseName": "opened",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TerminalModule.attributeTypeMap;
    }

    public constructor() {
    }
}

