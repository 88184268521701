import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { AcceptanceDeadline } from '../models/AcceptanceDeadline';
import { AppVersionResponse } from '../models/AppVersionResponse';
import { Booking } from '../models/Booking';
import { BookingForForwarderTO } from '../models/BookingForForwarderTO';
import { BookingRequest } from '../models/BookingRequest';
import { BookingsFilterRequestTO } from '../models/BookingsFilterRequestTO';
import { ColorBorder } from '../models/ColorBorder';
import { ConfirmBookingEntries } from '../models/ConfirmBookingEntries';
import { ConstantForwarderStatisticFloatValueResponseTO } from '../models/ConstantForwarderStatisticFloatValueResponseTO';
import { ConstantForwarderStatisticValueRequestTO } from '../models/ConstantForwarderStatisticValueRequestTO';
import { Container } from '../models/Container';
import { ContainerBookingCreationRequestTO } from '../models/ContainerBookingCreationRequestTO';
import { CreateForwarderUserTO } from '../models/CreateForwarderUserTO';
import { CreateTerminalOperatorUserTO } from '../models/CreateTerminalOperatorUserTO';
import { CreateTrainOperatorUserTO } from '../models/CreateTrainOperatorUserTO';
import { CustomerTO } from '../models/CustomerTO';
import { DangerousGoodsData } from '../models/DangerousGoodsData';
import { DeliveryTimes } from '../models/DeliveryTimes';
import { DepotContainerBookingTO } from '../models/DepotContainerBookingTO';
import { DepotGetStatusMultiRequestTO } from '../models/DepotGetStatusMultiRequestTO';
import { DepotGetStatusMultiResponseTO } from '../models/DepotGetStatusMultiResponseTO';
import { DepotGetStatusRequestTO } from '../models/DepotGetStatusRequestTO';
import { DepotGetStatusResponseTO } from '../models/DepotGetStatusResponseTO';
import { ErrorResponse } from '../models/ErrorResponse';
import { Forwarder } from '../models/Forwarder';
import { ForwarderApproval } from '../models/ForwarderApproval';
import { ForwarderUserTO } from '../models/ForwarderUserTO';
import { GetStatusDownloadCSVRequestTO } from '../models/GetStatusDownloadCSVRequestTO';
import { GetStatusRequest } from '../models/GetStatusRequest';
import { GetStatusRequestResponseTO } from '../models/GetStatusRequestResponseTO';
import { GetStatusResponse } from '../models/GetStatusResponse';
import { IspsChangeApprovalRequestTO } from '../models/IspsChangeApprovalRequestTO';
import { IspsLicenseKeyResponseTO } from '../models/IspsLicenseKeyResponseTO';
import { IspsLicenseTO } from '../models/IspsLicenseTO';
import { IspsTruckerChangeRequestTO } from '../models/IspsTruckerChangeRequestTO';
import { IspsTruckerChangeTO } from '../models/IspsTruckerChangeTO';
import { IspsTruckerTO } from '../models/IspsTruckerTO';
import { LocalTime } from '../models/LocalTime';
import { OneToOneRestriction } from '../models/OneToOneRestriction';
import { ParseGetStatusFileResponse } from '../models/ParseGetStatusFileResponse';
import { PickupTimes } from '../models/PickupTimes';
import { RangedTerminalStatisticsValueRequest } from '../models/RangedTerminalStatisticsValueRequest';
import { RangedTerminalStatisticsValueResponse } from '../models/RangedTerminalStatisticsValueResponse';
import { ResetPassword } from '../models/ResetPassword';
import { SealData } from '../models/SealData';
import { Terminal } from '../models/Terminal';
import { TerminalModule } from '../models/TerminalModule';
import { TerminalOpeningHour } from '../models/TerminalOpeningHour';
import { TerminalOperatorUserTO } from '../models/TerminalOperatorUserTO';
import { TerminalReduced } from '../models/TerminalReduced';
import { TimeSeriesRequest } from '../models/TimeSeriesRequest';
import { TimeSeriesResponse } from '../models/TimeSeriesResponse';
import { TimeSlot } from '../models/TimeSlot';
import { TollData } from '../models/TollData';
import { TourCreationRequestTO } from '../models/TourCreationRequestTO';
import { TourTO } from '../models/TourTO';
import { Train } from '../models/Train';
import { TrainLocationTO } from '../models/TrainLocationTO';
import { TrainOperator } from '../models/TrainOperator';
import { TrainOperatorBooking } from '../models/TrainOperatorBooking';
import { TrainOperatorUserTO } from '../models/TrainOperatorUserTO';
import { TrainOperatorUserTOLanguage } from '../models/TrainOperatorUserTOLanguage';
import { TruckerBlocklistRequestTO } from '../models/TruckerBlocklistRequestTO';
import { TruckerBlocklistResponseTO } from '../models/TruckerBlocklistResponseTO';
import { TruckerForForwarderTO } from '../models/TruckerForForwarderTO';
import { TruckerIdentificationTO } from '../models/TruckerIdentificationTO';
import { TruckerInformationTO } from '../models/TruckerInformationTO';
import { UpdateTruckerForForwarderTO } from '../models/UpdateTruckerForForwarderTO';
import { User } from '../models/User';
import { VerifyEmail } from '../models/VerifyEmail';
import { WasteData } from '../models/WasteData';
import { WebappUser } from '../models/WebappUser';

import { AcceptanceRestrictionsApiRequestFactory, AcceptanceRestrictionsApiResponseProcessor} from "../apis/AcceptanceRestrictionsApi";
export class ObservableAcceptanceRestrictionsApi {
    private requestFactory: AcceptanceRestrictionsApiRequestFactory;
    private responseProcessor: AcceptanceRestrictionsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AcceptanceRestrictionsApiRequestFactory,
        responseProcessor?: AcceptanceRestrictionsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AcceptanceRestrictionsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AcceptanceRestrictionsApiResponseProcessor();
    }

    /**
     * Creates acceptance deadline for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param acceptanceDeadline 
     */
    public createAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadline: AcceptanceDeadline, _options?: Configuration): Observable<AcceptanceDeadline> {
        const requestContextPromise = this.requestFactory.createAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadline, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createAcceptanceDeadlineForTerminal(rsp)));
            }));
    }

    /**
     * Creates one to one restriction for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param oneToOneRestriction 
     */
    public createOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestriction: OneToOneRestriction, _options?: Configuration): Observable<OneToOneRestriction> {
        const requestContextPromise = this.requestFactory.createOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestriction, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createOneToOneRestrictionForTerminal(rsp)));
            }));
    }

    /**
     * Deletes acceptance deadline for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param acceptanceDeadlineId id of acceptance deadline
     */
    public deleteAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteAcceptanceDeadlineForTerminal(rsp)));
            }));
    }

    /**
     * Deletes one to one restriction for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param oneToOneRestrictionId id of acceptance deadline
     */
    public deleteOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteOneToOneRestrictionForTerminal(rsp)));
            }));
    }

    /**
     * Gets all acceptance deadlines of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getAcceptanceDeadlinesForTerminal(userId: string, terminalId: number, _options?: Configuration): Observable<Array<AcceptanceDeadline>> {
        const requestContextPromise = this.requestFactory.getAcceptanceDeadlinesForTerminal(userId, terminalId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAcceptanceDeadlinesForTerminal(rsp)));
            }));
    }

    /**
     * Gets all one to one  restrictions of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getOneToOneRestrictionsForTerminal(userId: string, terminalId: number, _options?: Configuration): Observable<Array<OneToOneRestriction>> {
        const requestContextPromise = this.requestFactory.getOneToOneRestrictionsForTerminal(userId, terminalId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getOneToOneRestrictionsForTerminal(rsp)));
            }));
    }

    /**
     * Updates acceptance deadline for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param acceptanceDeadlineId id of acceptance deadline
     * @param acceptanceDeadline 
     */
    public updateAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, acceptanceDeadline: AcceptanceDeadline, _options?: Configuration): Observable<AcceptanceDeadline> {
        const requestContextPromise = this.requestFactory.updateAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, acceptanceDeadline, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateAcceptanceDeadlineForTerminal(rsp)));
            }));
    }

    /**
     * Updates one to one restriction  for a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param oneToOneRestrictionId id of acceptance deadline
     * @param oneToOneRestriction 
     */
    public updateOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, oneToOneRestriction: OneToOneRestriction, _options?: Configuration): Observable<OneToOneRestriction> {
        const requestContextPromise = this.requestFactory.updateOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, oneToOneRestriction, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateOneToOneRestrictionForTerminal(rsp)));
            }));
    }

}

import { ForwarderBookingsApiRequestFactory, ForwarderBookingsApiResponseProcessor} from "../apis/ForwarderBookingsApi";
export class ObservableForwarderBookingsApi {
    private requestFactory: ForwarderBookingsApiRequestFactory;
    private responseProcessor: ForwarderBookingsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderBookingsApiRequestFactory,
        responseProcessor?: ForwarderBookingsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ForwarderBookingsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ForwarderBookingsApiResponseProcessor();
    }

    /**
     * Cancels a booking
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingId Booking id
     */
    public cancelBooking(userId: string, forwarderId: number, truckerId: string, bookingId: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.cancelBooking(userId, forwarderId, truckerId, bookingId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.cancelBooking(rsp)));
            }));
    }

    /**
     * Performs a booking for a trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingRequest 
     */
    public createBookingForTrucker(userId: string, forwarderId: number, truckerId: string, bookingRequest: BookingRequest, _options?: Configuration): Observable<Booking> {
        const requestContextPromise = this.requestFactory.createBookingForTrucker(userId, forwarderId, truckerId, bookingRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createBookingForTrucker(rsp)));
            }));
    }

    /**
     * Get Booking by id for trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingId Booking id
     */
    public getBookingByIdForTrucker(userId: string, forwarderId: number, truckerId: string, bookingId: number, _options?: Configuration): Observable<BookingForForwarderTO> {
        const requestContextPromise = this.requestFactory.getBookingByIdForTrucker(userId, forwarderId, truckerId, bookingId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getBookingByIdForTrucker(rsp)));
            }));
    }

    /**
     * Export booking transhipment confirmation as pdf
     * Export booking transhipment confirmation as pdf
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker the booking belongs to
     * @param bookingId Booking id
     */
    public getTranshipmentConfirmationDocument(userId: string, forwarderId: number, truckerId: string, bookingId: number, _options?: Configuration): Observable<string> {
        const requestContextPromise = this.requestFactory.getTranshipmentConfirmationDocument(userId, forwarderId, truckerId, bookingId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTranshipmentConfirmationDocument(rsp)));
            }));
    }

    /**
     * Get all Bookings for forwarder
     * Get all Bookings for forwarder
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param bookingsFilterRequestTO 
     * @param page 
     * @param size 
     */
    public listBookingsForForwarder(userId: string, forwarderId: number, bookingsFilterRequestTO: BookingsFilterRequestTO, page?: number, size?: number, _options?: Configuration): Observable<Array<BookingForForwarderTO>> {
        const requestContextPromise = this.requestFactory.listBookingsForForwarder(userId, forwarderId, bookingsFilterRequestTO, page, size, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listBookingsForForwarder(rsp)));
            }));
    }

    /**
     * Gets all bookings for a specific trucker
     * Get all Bookings for trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId UUID that comes from firebase authentication
     * @param page 
     * @param size 
     */
    public listBookingsForTrucker(userId: string, forwarderId: number, truckerId: string, page?: number, size?: number, _options?: Configuration): Observable<Array<BookingForForwarderTO>> {
        const requestContextPromise = this.requestFactory.listBookingsForTrucker(userId, forwarderId, truckerId, page, size, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listBookingsForTrucker(rsp)));
            }));
    }

}

import { ForwarderContainersApiRequestFactory, ForwarderContainersApiResponseProcessor} from "../apis/ForwarderContainersApi";
export class ObservableForwarderContainersApi {
    private requestFactory: ForwarderContainersApiRequestFactory;
    private responseProcessor: ForwarderContainersApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderContainersApiRequestFactory,
        responseProcessor?: ForwarderContainersApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ForwarderContainersApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ForwarderContainersApiResponseProcessor();
    }

    /**
     * Downloads a csv with getStatus list requests and responses
     * Downloads a csv with getStatus list requests and responses
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     * @param getStatusDownloadCSVRequestTO 
     */
    public downloadGetStatusCsv(userId: string, forwarderId: number, getStatusDownloadCSVRequestTO: GetStatusDownloadCSVRequestTO, _options?: Configuration): Observable<string> {
        const requestContextPromise = this.requestFactory.downloadGetStatusCsv(userId, forwarderId, getStatusDownloadCSVRequestTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.downloadGetStatusCsv(rsp)));
            }));
    }

    /**
     * Indicates whether a booking is possible to be booked and supplies necessary information.
     * Requests the status of a loading unit
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     * @param terminalId The terminal id of the terminal to be called
     * @param getStatusRequest 
     * @param requestType place where get status was triggered
     */
    public getStatus1(userId: string, forwarderId: number, terminalId: number, getStatusRequest: GetStatusRequest, requestType?: 'single_get_status' | 'list_get_status', _options?: Configuration): Observable<GetStatusResponse> {
        const requestContextPromise = this.requestFactory.getStatus1(userId, forwarderId, terminalId, getStatusRequest, requestType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getStatus1(rsp)));
            }));
    }

    /**
     * parses csv file for container data and returns getStatusRequests to be done by webapp.
     * parses csv file for container data and returns getStatusRequests to be done by webapp
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     * @param file Csv file containing container data to be parsed
     */
    public parseGetStatusFile(userId: string, forwarderId: number, file: HttpFile, _options?: Configuration): Observable<ParseGetStatusFileResponse> {
        const requestContextPromise = this.requestFactory.parseGetStatusFile(userId, forwarderId, file, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.parseGetStatusFile(rsp)));
            }));
    }

}

import { ForwarderOperatorForwardersApiRequestFactory, ForwarderOperatorForwardersApiResponseProcessor} from "../apis/ForwarderOperatorForwardersApi";
export class ObservableForwarderOperatorForwardersApi {
    private requestFactory: ForwarderOperatorForwardersApiRequestFactory;
    private responseProcessor: ForwarderOperatorForwardersApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderOperatorForwardersApiRequestFactory,
        responseProcessor?: ForwarderOperatorForwardersApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ForwarderOperatorForwardersApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ForwarderOperatorForwardersApiResponseProcessor();
    }

    /**
     * gets all forwarders
     * @param userId UUID that comes from firebase authentication
     */
    public getAllForwarders(userId: string, _options?: Configuration): Observable<Array<Forwarder>> {
        const requestContextPromise = this.requestFactory.getAllForwarders(userId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAllForwarders(rsp)));
            }));
    }

    /**
     * gets forwarder by id
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId forwarderId as long parameter
     */
    public getForwarderById(userId: string, forwarderId: number, _options?: Configuration): Observable<Forwarder> {
        const requestContextPromise = this.requestFactory.getForwarderById(userId, forwarderId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getForwarderById(rsp)));
            }));
    }

}

import { ForwarderOperatorTerminalApiApiRequestFactory, ForwarderOperatorTerminalApiApiResponseProcessor} from "../apis/ForwarderOperatorTerminalApiApi";
export class ObservableForwarderOperatorTerminalApiApi {
    private requestFactory: ForwarderOperatorTerminalApiApiRequestFactory;
    private responseProcessor: ForwarderOperatorTerminalApiApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderOperatorTerminalApiApiRequestFactory,
        responseProcessor?: ForwarderOperatorTerminalApiApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ForwarderOperatorTerminalApiApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ForwarderOperatorTerminalApiApiResponseProcessor();
    }

    /**
     * Gets all one to one  restrictions of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the Forwarder
     * @param terminalId id of requested terminal
     */
    public getOneToOneRestrictionsForForwarderOperatorForTerminal(userId: string, forwarderId: number, terminalId: number, _options?: Configuration): Observable<Array<OneToOneRestriction>> {
        const requestContextPromise = this.requestFactory.getOneToOneRestrictionsForForwarderOperatorForTerminal(userId, forwarderId, terminalId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getOneToOneRestrictionsForForwarderOperatorForTerminal(rsp)));
            }));
    }

    /**
     * Takes a User ID, Forwarder ID  and returns all existing terminals
     * Gets all terminals for forwarder operator
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of Forwarder
     */
    public getTerminalsForForwarderOperator(userId: string, forwarderId: number, _options?: Configuration): Observable<Array<TerminalReduced>> {
        const requestContextPromise = this.requestFactory.getTerminalsForForwarderOperator(userId, forwarderId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTerminalsForForwarderOperator(rsp)));
            }));
    }

}

import { ForwarderTruckersApiApiRequestFactory, ForwarderTruckersApiApiResponseProcessor} from "../apis/ForwarderTruckersApiApi";
export class ObservableForwarderTruckersApiApi {
    private requestFactory: ForwarderTruckersApiApiRequestFactory;
    private responseProcessor: ForwarderTruckersApiApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderTruckersApiApiRequestFactory,
        responseProcessor?: ForwarderTruckersApiApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ForwarderTruckersApiApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ForwarderTruckersApiApiResponseProcessor();
    }

    /**
     * Approves a trucker to have this forwarder
     * Approves a trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker to update
     * @param forwarderApproval 
     */
    public approveTrucker(userId: string, forwarderId: number, truckerId: string, forwarderApproval: ForwarderApproval, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.approveTrucker(userId, forwarderId, truckerId, forwarderApproval, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.approveTrucker(rsp)));
            }));
    }

    /**
     * Deletes current trucker
     * Delete trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker to get
     */
    public deleteTrucker(userId: string, forwarderId: number, truckerId: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteTrucker(userId, forwarderId, truckerId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteTrucker(rsp)));
            }));
    }

    /**
     * Gets all approved truckers for forwarder
     * Gets all approved Truckers for forwarder
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     */
    public getAllApprovedTruckers(userId: string, forwarderId: number, _options?: Configuration): Observable<Array<User>> {
        const requestContextPromise = this.requestFactory.getAllApprovedTruckers(userId, forwarderId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAllApprovedTruckers(rsp)));
            }));
    }

    /**
     * Gets all current available truckers for forwarder
     * Gets all registered Truckers for forwarder
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param page 
     * @param size 
     */
    public getAllTruckersForForwarder(userId: string, forwarderId: number, page?: number, size?: number, _options?: Configuration): Observable<Array<TruckerForForwarderTO>> {
        const requestContextPromise = this.requestFactory.getAllTruckersForForwarder(userId, forwarderId, page, size, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAllTruckersForForwarder(rsp)));
            }));
    }

    /**
     * Gets trucker information by his id 
     * Gets trucker by id
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId 
     */
    public getTruckerById(userId: string, forwarderId: number, truckerId: string, _options?: Configuration): Observable<TruckerForForwarderTO> {
        const requestContextPromise = this.requestFactory.getTruckerById(userId, forwarderId, truckerId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTruckerById(rsp)));
            }));
    }

    /**
     * Updates truck driver information
     * Updates trucker
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param truckerId id of the trucker to get
     * @param updateTruckerForForwarderTO 
     */
    public updateTrucker(userId: string, forwarderId: number, truckerId: string, updateTruckerForForwarderTO: UpdateTruckerForForwarderTO, _options?: Configuration): Observable<TruckerForForwarderTO> {
        const requestContextPromise = this.requestFactory.updateTrucker(userId, forwarderId, truckerId, updateTruckerForForwarderTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateTrucker(rsp)));
            }));
    }

}

import { ForwarderUserApiRequestFactory, ForwarderUserApiResponseProcessor} from "../apis/ForwarderUserApi";
export class ObservableForwarderUserApi {
    private requestFactory: ForwarderUserApiRequestFactory;
    private responseProcessor: ForwarderUserApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ForwarderUserApiRequestFactory,
        responseProcessor?: ForwarderUserApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ForwarderUserApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ForwarderUserApiResponseProcessor();
    }

    /**
     * Updates a Forwarder User Role
     * @param userId Admin user id
     * @param forwarderUserTO 
     */
    public addRoleForForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, _options?: Configuration): Observable<ForwarderUserTO> {
        const requestContextPromise = this.requestFactory.addRoleForForwarderUser(userId, forwarderUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.addRoleForForwarderUser(rsp)));
            }));
    }

    /**
     * Create a Forwarder User
     * @param userId Admin user id
     * @param createForwarderUserTO 
     */
    public createUserForForwarder(userId: string, createForwarderUserTO: CreateForwarderUserTO, _options?: Configuration): Observable<ForwarderUserTO> {
        const requestContextPromise = this.requestFactory.createUserForForwarder(userId, createForwarderUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createUserForForwarder(rsp)));
            }));
    }

    /**
     * Deletes forwarder user entity
     * @param userId Admin user id
     * @param forwarderUserId User to be deleted
     */
    public removeForwarderUserRole(userId: string, forwarderUserId: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.removeForwarderUserRole(userId, forwarderUserId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.removeForwarderUserRole(rsp)));
            }));
    }

    /**
     * Updates forwarder user entity
     * @param userId Admin user id
     * @param forwarderUserTO 
     */
    public updateForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, _options?: Configuration): Observable<ForwarderUserTO> {
        const requestContextPromise = this.requestFactory.updateForwarderUser(userId, forwarderUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateForwarderUser(rsp)));
            }));
    }

}

import { IspsAdminUsersApiRequestFactory, IspsAdminUsersApiResponseProcessor} from "../apis/IspsAdminUsersApi";
export class ObservableIspsAdminUsersApi {
    private requestFactory: IspsAdminUsersApiRequestFactory;
    private responseProcessor: IspsAdminUsersApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: IspsAdminUsersApiRequestFactory,
        responseProcessor?: IspsAdminUsersApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new IspsAdminUsersApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new IspsAdminUsersApiResponseProcessor();
    }

    /**
     * Deletes TruckerIdentification tries and automatically approves
     * @param userId UUID that comes from firebase authentication
     * @param truckerId Trucker id that needs to be reset
     */
    public deleteTruckerIdentifications(userId: string, truckerId: string, _options?: Configuration): Observable<IspsTruckerTO> {
        const requestContextPromise = this.requestFactory.deleteTruckerIdentifications(userId, truckerId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteTruckerIdentifications(rsp)));
            }));
    }

    /**
     * get for trucker in regards of identification and licensing
     * get Isps Trucker
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be retrieved
     * @param request 
     */
    public getIspsTrucker(userId: string, truckerId: string, request: IspsTruckerTO, _options?: Configuration): Observable<IspsTruckerTO> {
        const requestContextPromise = this.requestFactory.getIspsTrucker(userId, truckerId, request, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getIspsTrucker(rsp)));
            }));
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * get Isps Truckers
     * @param userId UUID that comes from firebase authentication
     * @param documentIdentificationStatus Document Identification Status
     * @param terrorDatabaseStatus Terror Database Status
     * @param truckerId Trucker ID
     * @param page Page number
     * @param size 
     */
    public getIspsTruckers(userId: string, documentIdentificationStatus?: 'ACCEPTED' | 'PENDING' | 'DENIED' | 'WARN' | 'DENIED_PRELIMINARY' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED' | 'FAILED', terrorDatabaseStatus?: 'OK' | 'WARNING' | 'RED_ALERT' | 'MANUALLY_APPROVED' | 'MANUALLY_DENIED', truckerId?: string, page?: number, size?: number, _options?: Configuration): Observable<Array<IspsTruckerTO>> {
        const requestContextPromise = this.requestFactory.getIspsTruckers(userId, documentIdentificationStatus, terrorDatabaseStatus, truckerId, page, size, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getIspsTruckers(rsp)));
            }));
    }

    /**
     * cerate for trucker in regards of identification and licensing
     * create isps trucker log
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be edited
     * @param ispsTruckerChangeRequestTO 
     */
    public ispsTruckerLog(userId: string, truckerId: string, ispsTruckerChangeRequestTO: IspsTruckerChangeRequestTO, _options?: Configuration): Observable<IspsTruckerTO> {
        const requestContextPromise = this.requestFactory.ispsTruckerLog(userId, truckerId, ispsTruckerChangeRequestTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.ispsTruckerLog(rsp)));
            }));
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * Patch Isps Trucker
     * @param userId UUID that comes from firebase authentication
     * @param truckerId UUID of trucker to be approved
     * @param changeId Id of the change to be approved
     * @param ispsChangeApprovalRequestTO 
     */
    public patchIspsTrucker(userId: string, truckerId: string, changeId: number, ispsChangeApprovalRequestTO: IspsChangeApprovalRequestTO, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.patchIspsTrucker(userId, truckerId, changeId, ispsChangeApprovalRequestTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.patchIspsTrucker(rsp)));
            }));
    }

}

import { IspsTerminalOperatorBlocklistApiRequestFactory, IspsTerminalOperatorBlocklistApiResponseProcessor} from "../apis/IspsTerminalOperatorBlocklistApi";
export class ObservableIspsTerminalOperatorBlocklistApi {
    private requestFactory: IspsTerminalOperatorBlocklistApiRequestFactory;
    private responseProcessor: IspsTerminalOperatorBlocklistApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: IspsTerminalOperatorBlocklistApiRequestFactory,
        responseProcessor?: IspsTerminalOperatorBlocklistApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new IspsTerminalOperatorBlocklistApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new IspsTerminalOperatorBlocklistApiResponseProcessor();
    }

    /**
     * Creates blocklist entry for isps terminal
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested facility
     * @param truckerBlocklistRequestTO 
     */
    public createBlockListEntryForIspsTerminal(userId: string, facilityId: number, truckerBlocklistRequestTO: TruckerBlocklistRequestTO, _options?: Configuration): Observable<TruckerBlocklistResponseTO> {
        const requestContextPromise = this.requestFactory.createBlockListEntryForIspsTerminal(userId, facilityId, truckerBlocklistRequestTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createBlockListEntryForIspsTerminal(rsp)));
            }));
    }

    /**
     * Deletes  blocklist entry for isps terminal
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested facility
     * @param blocklistId id of blocklist entry
     */
    public deleteBlockListEntryForIspsTerminal(userId: string, facilityId: number, blocklistId: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteBlockListEntryForIspsTerminal(userId, facilityId, blocklistId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteBlockListEntryForIspsTerminal(rsp)));
            }));
    }

    /**
     * Gets all blocklist entries of a facility
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested terminal
     */
    public getBlocklistEntiresForFacility(userId: string, facilityId: number, _options?: Configuration): Observable<Array<TruckerBlocklistResponseTO>> {
        const requestContextPromise = this.requestFactory.getBlocklistEntiresForFacility(userId, facilityId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getBlocklistEntiresForFacility(rsp)));
            }));
    }

}

import { MirrorAppUserVersionsApiRequestFactory, MirrorAppUserVersionsApiResponseProcessor} from "../apis/MirrorAppUserVersionsApi";
export class ObservableMirrorAppUserVersionsApi {
    private requestFactory: MirrorAppUserVersionsApiRequestFactory;
    private responseProcessor: MirrorAppUserVersionsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: MirrorAppUserVersionsApiRequestFactory,
        responseProcessor?: MirrorAppUserVersionsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new MirrorAppUserVersionsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new MirrorAppUserVersionsApiResponseProcessor();
    }

    /**
     * Gets all specific versions per query filtering
     * @param userId UUID that comes from firebase authentication
     * @param appVersion 
     */
    public getAllHostedAppVersions(userId: string, appVersion: 'android' | 'ios', _options?: Configuration): Observable<Array<AppVersionResponse>> {
        const requestContextPromise = this.requestFactory.getAllHostedAppVersions(userId, appVersion, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAllHostedAppVersions(rsp)));
            }));
    }

}

import { StatisticsApiRequestFactory, StatisticsApiResponseProcessor} from "../apis/StatisticsApi";
export class ObservableStatisticsApi {
    private requestFactory: StatisticsApiRequestFactory;
    private responseProcessor: StatisticsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: StatisticsApiRequestFactory,
        responseProcessor?: StatisticsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new StatisticsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new StatisticsApiResponseProcessor();
    }

    /**
     * Gets a specific statistic value
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param rangedTerminalStatisticsValueRequest 
     */
    public getStatisticFloatValue(userId: string, terminalId: number, rangedTerminalStatisticsValueRequest: RangedTerminalStatisticsValueRequest, _options?: Configuration): Observable<RangedTerminalStatisticsValueResponse> {
        const requestContextPromise = this.requestFactory.getStatisticFloatValue(userId, terminalId, rangedTerminalStatisticsValueRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getStatisticFloatValue(rsp)));
            }));
    }

    /**
     * Gets a specific statistic timeSeries
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param timeSeriesRequest 
     */
    public getTimeSeries(userId: string, terminalId: number, timeSeriesRequest: TimeSeriesRequest, _options?: Configuration): Observable<TimeSeriesResponse> {
        const requestContextPromise = this.requestFactory.getTimeSeries(userId, terminalId, timeSeriesRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTimeSeries(rsp)));
            }));
    }

}

import { TerminalOperatorUserApiRequestFactory, TerminalOperatorUserApiResponseProcessor} from "../apis/TerminalOperatorUserApi";
export class ObservableTerminalOperatorUserApi {
    private requestFactory: TerminalOperatorUserApiRequestFactory;
    private responseProcessor: TerminalOperatorUserApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TerminalOperatorUserApiRequestFactory,
        responseProcessor?: TerminalOperatorUserApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TerminalOperatorUserApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TerminalOperatorUserApiResponseProcessor();
    }

    /**
     * Updates a terminal operator User Role
     * @param userId Admin user id
     * @param terminalOperatorUserTO 
     */
    public addTerminalOperatorUserRole(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, _options?: Configuration): Observable<TerminalOperatorUserTO> {
        const requestContextPromise = this.requestFactory.addTerminalOperatorUserRole(userId, terminalOperatorUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.addTerminalOperatorUserRole(rsp)));
            }));
    }

    /**
     * Create a Terminal Operator User
     * @param userId Admin user id
     * @param createTerminalOperatorUserTO 
     */
    public createUserForTerminalOperator(userId: string, createTerminalOperatorUserTO: CreateTerminalOperatorUserTO, _options?: Configuration): Observable<TerminalOperatorUserTO> {
        const requestContextPromise = this.requestFactory.createUserForTerminalOperator(userId, createTerminalOperatorUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createUserForTerminalOperator(rsp)));
            }));
    }

    /**
     * Deletes terminal user entity
     * @param userId Admin user id
     * @param terminalOperatorUserId User to be deleted
     */
    public removeTerminalOperatorUserRole(userId: string, terminalOperatorUserId: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.removeTerminalOperatorUserRole(userId, terminalOperatorUserId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.removeTerminalOperatorUserRole(rsp)));
            }));
    }

    /**
     * Updates terminal operator user entity
     * @param userId Admin user id
     * @param terminalOperatorUserTO 
     */
    public updateTerminalOperatorUser(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, _options?: Configuration): Observable<TerminalOperatorUserTO> {
        const requestContextPromise = this.requestFactory.updateTerminalOperatorUser(userId, terminalOperatorUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateTerminalOperatorUser(rsp)));
            }));
    }

}

import { TerminalUsersApiRequestFactory, TerminalUsersApiResponseProcessor} from "../apis/TerminalUsersApi";
export class ObservableTerminalUsersApi {
    private requestFactory: TerminalUsersApiRequestFactory;
    private responseProcessor: TerminalUsersApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TerminalUsersApiRequestFactory,
        responseProcessor?: TerminalUsersApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TerminalUsersApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TerminalUsersApiResponseProcessor();
    }

    /**
     * This can only be done by the logged in user.
     * Delete user
     * @param userId UUID that comes from firebase authentication
     */
    public deleteUser(userId: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteUser(userId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteUser(rsp)));
            }));
    }

    /**
     * Gets all terminals for user
     * @param userId UUID that comes from firebase authentication
     */
    public getTerminalsForUser(userId: string, _options?: Configuration): Observable<Array<Terminal>> {
        const requestContextPromise = this.requestFactory.getTerminalsForUser(userId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTerminalsForUser(rsp)));
            }));
    }

    /**
     * Get user by Id
     * Get user
     * @param userId UUID that comes from firebase authentication
     */
    public getUserById(userId: string, _options?: Configuration): Observable<WebappUser> {
        const requestContextPromise = this.requestFactory.getUserById(userId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getUserById(rsp)));
            }));
    }

    /**
     * resets password for terminal operator
     * reset password
     * @param resetPassword 
     */
    public resetPassword(resetPassword: ResetPassword, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.resetPassword(resetPassword, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.resetPassword(rsp)));
            }));
    }

    /**
     * This can only be done by the logged in user.
     * Updates user
     * @param userId UUID that comes from firebase authentication
     * @param webappUser 
     */
    public updateUser(userId: string, webappUser: WebappUser, _options?: Configuration): Observable<WebappUser> {
        const requestContextPromise = this.requestFactory.updateUser(userId, webappUser, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateUser(rsp)));
            }));
    }

    /**
     * verify email for terminal operator
     * verify email
     * @param verifyEmail 
     */
    public verifyEmail(verifyEmail: VerifyEmail, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.verifyEmail(verifyEmail, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.verifyEmail(rsp)));
            }));
    }

}

import { TerminalsApiRequestFactory, TerminalsApiResponseProcessor} from "../apis/TerminalsApi";
export class ObservableTerminalsApi {
    private requestFactory: TerminalsApiRequestFactory;
    private responseProcessor: TerminalsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TerminalsApiRequestFactory,
        responseProcessor?: TerminalsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TerminalsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TerminalsApiResponseProcessor();
    }

    /**
     * Gets a specific terminal module by Id
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     * @param moduleId id of requested terminal module
     */
    public getModuleById(userId: string, terminalId: number, moduleId: number, _options?: Configuration): Observable<TerminalModule> {
        const requestContextPromise = this.requestFactory.getModuleById(userId, terminalId, moduleId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getModuleById(rsp)));
            }));
    }

    /**
     * Gets all modules of a terminal
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getModulesForTerminal(userId: string, terminalId: number, _options?: Configuration): Observable<Array<TerminalModule>> {
        const requestContextPromise = this.requestFactory.getModulesForTerminal(userId, terminalId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getModulesForTerminal(rsp)));
            }));
    }

    /**
     * Gets a specific terminal by Id
     * @param userId UUID that comes from firebase authentication
     * @param terminalId id of requested terminal
     */
    public getTerminalById(userId: string, terminalId: number, _options?: Configuration): Observable<Terminal> {
        const requestContextPromise = this.requestFactory.getTerminalById(userId, terminalId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTerminalById(rsp)));
            }));
    }

}

import { TrainOperatorBookingsApiRequestFactory, TrainOperatorBookingsApiResponseProcessor} from "../apis/TrainOperatorBookingsApi";
export class ObservableTrainOperatorBookingsApi {
    private requestFactory: TrainOperatorBookingsApiRequestFactory;
    private responseProcessor: TrainOperatorBookingsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TrainOperatorBookingsApiRequestFactory,
        responseProcessor?: TrainOperatorBookingsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TrainOperatorBookingsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TrainOperatorBookingsApiResponseProcessor();
    }

    /**
     * confirms bookings from train operator
     * @param userId UUID that comes from firebase authentication
     * @param trainOperatorId id of train operator
     * @param terminalId id of requested terminal
     * @param confirmBookingEntries 
     */
    public confirmBookingEntries(userId: string, trainOperatorId: number, terminalId: number, confirmBookingEntries: ConfirmBookingEntries, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.confirmBookingEntries(userId, trainOperatorId, terminalId, confirmBookingEntries, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.confirmBookingEntries(rsp)));
            }));
    }

    /**
     * Gets current available train operators
     * @param userId UUID that comes from firebase authentication
     */
    public getAvailableTrainOperators(userId: string, _options?: Configuration): Observable<Array<TrainOperator>> {
        const requestContextPromise = this.requestFactory.getAvailableTrainOperators(userId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAvailableTrainOperators(rsp)));
            }));
    }

    /**
     * Gets bookings for trainOperator and terminal(s)
     * @param userId UUID that comes from firebase authentication
     * @param trainOperatorId id of train operator
     * @param terminalId id of requested terminal
     * @param page page
     * @param size size
     * @param active boolean whether active or non active bookings should be supplied
     */
    public getBookingsForTrainOperator(userId: string, trainOperatorId: number, terminalId: number, page: number, size: number, active?: boolean, _options?: Configuration): Observable<Array<TrainOperatorBooking>> {
        const requestContextPromise = this.requestFactory.getBookingsForTrainOperator(userId, trainOperatorId, terminalId, page, size, active, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getBookingsForTrainOperator(rsp)));
            }));
    }

}

import { TrainOperatorUserApiRequestFactory, TrainOperatorUserApiResponseProcessor} from "../apis/TrainOperatorUserApi";
export class ObservableTrainOperatorUserApi {
    private requestFactory: TrainOperatorUserApiRequestFactory;
    private responseProcessor: TrainOperatorUserApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TrainOperatorUserApiRequestFactory,
        responseProcessor?: TrainOperatorUserApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TrainOperatorUserApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TrainOperatorUserApiResponseProcessor();
    }

    /**
     * Updates a Train operator User Role
     * @param userId Admin user id
     * @param trainOperatorUserTO 
     */
    public addTrainOperatorUserRole(userId: string, trainOperatorUserTO: TrainOperatorUserTO, _options?: Configuration): Observable<TrainOperatorUserTO> {
        const requestContextPromise = this.requestFactory.addTrainOperatorUserRole(userId, trainOperatorUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.addTrainOperatorUserRole(rsp)));
            }));
    }

    /**
     * Create a Train Operator User
     * @param userId Admin user id
     * @param createTrainOperatorUserTO 
     */
    public createUserForTrainOperator(userId: string, createTrainOperatorUserTO: CreateTrainOperatorUserTO, _options?: Configuration): Observable<TrainOperatorUserTO> {
        const requestContextPromise = this.requestFactory.createUserForTrainOperator(userId, createTrainOperatorUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createUserForTrainOperator(rsp)));
            }));
    }

    /**
     * Deletes train operator user entity
     * @param userId Admin user id
     * @param trainOperatorUserId User to be deleted
     */
    public removeTrainOperatorUserRole(userId: string, trainOperatorUserId: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.removeTrainOperatorUserRole(userId, trainOperatorUserId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.removeTrainOperatorUserRole(rsp)));
            }));
    }

    /**
     * Updates train operator user entity
     * @param userId Admin user id
     * @param trainOperatorUserTO 
     */
    public updateTrainOperatorUser(userId: string, trainOperatorUserTO: TrainOperatorUserTO, _options?: Configuration): Observable<TrainOperatorUserTO> {
        const requestContextPromise = this.requestFactory.updateTrainOperatorUser(userId, trainOperatorUserTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateTrainOperatorUser(rsp)));
            }));
    }

}

import { WebappDepotsApiRequestFactory, WebappDepotsApiResponseProcessor} from "../apis/WebappDepotsApi";
export class ObservableWebappDepotsApi {
    private requestFactory: WebappDepotsApiRequestFactory;
    private responseProcessor: WebappDepotsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: WebappDepotsApiRequestFactory,
        responseProcessor?: WebappDepotsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new WebappDepotsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new WebappDepotsApiResponseProcessor();
    }

    /**
     * Creates a new tour object in the data base with the contained bookings
     * Creates Tour
     * @param userId UUID that comes from firebase authentication
     * @param depotId UUID that defines the depot
     * @param tourCreationRequestTO 
     */
    public createTour(userId: string, depotId: number, tourCreationRequestTO: TourCreationRequestTO, _options?: Configuration): Observable<TourTO> {
        const requestContextPromise = this.requestFactory.createTour(userId, depotId, tourCreationRequestTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createTour(rsp)));
            }));
    }

    /**
     * Get all customers that belong to a depot
     * Get list of all customers per depot-facilities
     * @param userId UUID that comes from firebase authentication
     * @param depotId UUID that defines the depot
     */
    public getAllCustomers(userId: string, depotId: number, _options?: Configuration): Observable<Array<CustomerTO>> {
        const requestContextPromise = this.requestFactory.getAllCustomers(userId, depotId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAllCustomers(rsp)));
            }));
    }

    /**
     * Get status of multiple container requests
     * Get status of multiple container requests
     * @param userId UUID that comes from firebase authentication
     * @param depotId UUID that defines the depot
     * @param depotGetStatusMultiRequestTO 
     */
    public getStatus(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, _options?: Configuration): Observable<DepotGetStatusMultiResponseTO> {
        const requestContextPromise = this.requestFactory.getStatus(userId, depotId, depotGetStatusMultiRequestTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getStatus(rsp)));
            }));
    }

}

import { WebappStatisticsForForwarderApiRequestFactory, WebappStatisticsForForwarderApiResponseProcessor} from "../apis/WebappStatisticsForForwarderApi";
export class ObservableWebappStatisticsForForwarderApi {
    private requestFactory: WebappStatisticsForForwarderApiRequestFactory;
    private responseProcessor: WebappStatisticsForForwarderApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: WebappStatisticsForForwarderApiRequestFactory,
        responseProcessor?: WebappStatisticsForForwarderApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new WebappStatisticsForForwarderApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new WebappStatisticsForForwarderApiResponseProcessor();
    }

    /**
     * Gets statistical float value for the forwarders
     * Gets a specific statistic value
     * @param userId UUID that comes from firebase authentication
     * @param forwarderId id of the forwarder
     * @param constantForwarderStatisticValueRequestTO 
     */
    public getStatisticFloatValue1(userId: string, forwarderId: number, constantForwarderStatisticValueRequestTO: ConstantForwarderStatisticValueRequestTO, _options?: Configuration): Observable<ConstantForwarderStatisticFloatValueResponseTO> {
        const requestContextPromise = this.requestFactory.getStatisticFloatValue1(userId, forwarderId, constantForwarderStatisticValueRequestTO, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getStatisticFloatValue1(rsp)));
            }));
    }

}
