/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ColorBorder } from '../models/ColorBorder';
import { HttpFile } from '../http/http';

/**
* Time series response
*/
export class TimeSeriesResponse {
    /**
    * Statistic Name
    */
    'name': TimeSeriesResponseNameEnum;
    'seriesStart': Date;
    'seriesEnd': Date;
    'xAxis': Array<string>;
    'yAxis': Array<number>;
    'unit'?: string;
    'colorBorders'?: Array<ColorBorder>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "TimeSeriesResponseNameEnum",
            "format": ""
        },
        {
            "name": "seriesStart",
            "baseName": "seriesStart",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "seriesEnd",
            "baseName": "seriesEnd",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "xAxis",
            "baseName": "xAxis",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "yAxis",
            "baseName": "yAxis",
            "type": "Array<number>",
            "format": "float"
        },
        {
            "name": "unit",
            "baseName": "unit",
            "type": "string",
            "format": ""
        },
        {
            "name": "colorBorders",
            "baseName": "colorBorders",
            "type": "Array<ColorBorder>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TimeSeriesResponse.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TimeSeriesResponseNameEnum = "active_bookings" | "completed_bookings" | "canceled_bookings" | "time_register_success_transhipment" ;

