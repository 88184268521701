/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TerminalModule } from '../models/TerminalModule';
import { TerminalOpeningHour } from '../models/TerminalOpeningHour';
import { HttpFile } from '../http/http';

/**
* Terminal
*/
export class Terminal {
    'id': number;
    'name': string;
    'logoUrl': string;
    'latPosition': number;
    'lonPosition': number;
    'modules'?: Array<TerminalModule>;
    'openingHours'?: Array<TerminalOpeningHour>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "logoUrl",
            "baseName": "logoUrl",
            "type": "string",
            "format": ""
        },
        {
            "name": "latPosition",
            "baseName": "latPosition",
            "type": "number",
            "format": "double"
        },
        {
            "name": "lonPosition",
            "baseName": "lonPosition",
            "type": "number",
            "format": "double"
        },
        {
            "name": "modules",
            "baseName": "modules",
            "type": "Array<TerminalModule>",
            "format": ""
        },
        {
            "name": "openingHours",
            "baseName": "openingHours",
            "type": "Array<TerminalOpeningHour>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Terminal.attributeTypeMap;
    }

    public constructor() {
    }
}

