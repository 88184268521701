/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Updated user object
*/
export class WebappUser {
    'id': string;
    'locale'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'email'?: string;
    'roles'?: Array<WebappUserRolesEnum>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "locale",
            "baseName": "locale",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "roles",
            "baseName": "roles",
            "type": "Array<WebappUserRolesEnum>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return WebappUser.attributeTypeMap;
    }

    public constructor() {
    }
}


export type WebappUserRolesEnum = "terminal_operations_read" | "terminal_operations_write" | "terminal_operations_admin" | "forwarder_read" | "forwarder_write" | "train_operator_read" | "train_operator_write" | "conroo_read" | "conroo_write" | "conroo_admin" | "mirror_app_read" | "mirror_app_write" | "isps_terminal_operations_read" | "isps_terminal_operations_write" ;

