<template>
  <div class="flex justify-center self-center home">
    <LoginForm :error-message="errorMessage" @sign-in="login"></LoginForm>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import LoginForm from "./LoginForm.vue";
import { getAuthService } from "@/services/auth/auth-service";
import { useRouter } from "vue-router";
import { getUserClient } from "@/services/api-client";
import { useUserStore } from "@/observables/store/user-store";
import { isConrooUser } from "@/util/role-util";

const authService = getAuthService();
const userClient = getUserClient();
const userStore = useUserStore();
//TODO: encapsulate both into a common api, this project needs to be done fast
const router = useRouter();

const errorMessage = ref("");

const login = async (payload: {
  email: string;
  password: string;
}): Promise<void> => {
  errorMessage.value = "";

  try {
    const { email, password } = payload;
    const firebaseUser = await authService.login(email, password);
    const userData = await userClient.getUserById(firebaseUser.userId);
    if (!isConrooUser(userData.roles ?? [])) {
      errorMessage.value = "Unauthorized";
      return;
    }
    userStore.updateUser(userData);
    router.push("/usersManagement");
  } catch (e: unknown) {
    //shitty handling
    if (typeof e === "object" && e != null && "code" in e) {
      errorMessage.value = e.code as string;
    } else {
      errorMessage.value = "General error";
    }
  }
};
</script>

<style>
.home {
  background-image: url("~@/assets/media/images/main-background.png");
  height: 100vh;
}
</style>
