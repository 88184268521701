<template>
  <div :class="className">
    <span>{{ $props.message }}</span>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, computed } from "vue";
import { ToastLevel } from "@/observables/store/toast-store";

const className = computed(() => {
  return `alert alert-${props.level}`;
});

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  level: {
    type: String as PropType<ToastLevel>,
    required: true,
  },
});
</script>
