/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ColorBorder } from '../models/ColorBorder';
import { HttpFile } from '../http/http';

export class ConstantForwarderStatisticFloatValueResponseTO {
    'floatValue': number;
    'unit': string;
    'colorBorders': Array<ColorBorder>;
    /**
    * Forwarder Statistic Name
    */
    'name': ConstantForwarderStatisticFloatValueResponseTONameEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "floatValue",
            "baseName": "floatValue",
            "type": "number",
            "format": "float"
        },
        {
            "name": "unit",
            "baseName": "unit",
            "type": "string",
            "format": ""
        },
        {
            "name": "colorBorders",
            "baseName": "colorBorders",
            "type": "Array<ColorBorder>",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "ConstantForwarderStatisticFloatValueResponseTONameEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ConstantForwarderStatisticFloatValueResponseTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ConstantForwarderStatisticFloatValueResponseTONameEnum = "open_orders" | "registered_truckers" ;

