/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* WasteData
*/
export class WasteData {
    'id'?: number;
    'eAKKeyNumber': string;
    'aVVNumber': string;
    'voucherNumber': string;
    'addedBy': WasteDataAddedByEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "eAKKeyNumber",
            "baseName": "eAKKeyNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "aVVNumber",
            "baseName": "aVVNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "voucherNumber",
            "baseName": "voucherNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "addedBy",
            "baseName": "addedBy",
            "type": "WasteDataAddedByEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return WasteData.attributeTypeMap;
    }

    public constructor() {
    }
}


export type WasteDataAddedByEnum = "trucker" | "terminal" | "forwarder_operator" ;

