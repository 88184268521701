<template>
  <div v-if="props.isEditable">
    <DropDown
      :selected-value="props.selectedValue"
      @select="(t : string) => $emit('select', t)"
      :title="props.title"
      :entries="props.entries"
    ></DropDown>
  </div>
  <div v-else>{{ props.title }}</div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

import DropDown from "@/components/DropDown.vue";

const props = defineProps<{
  entries: string[];
  title: string;
  selectedValue: string | null | undefined;
  isEditable?: boolean;
}>();
</script>
