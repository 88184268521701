/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Container } from '../models/Container';
import { TimeSlot } from '../models/TimeSlot';
import { HttpFile } from '../http/http';

/**
* GetStatus Response
*/
export class GetStatusResponse {
    'container'?: Container;
    'timeSlots'?: Array<TimeSlot>;
    'referenceNumber': string;
    'bookingTerminalStatus'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "container",
            "baseName": "container",
            "type": "Container",
            "format": ""
        },
        {
            "name": "timeSlots",
            "baseName": "timeSlots",
            "type": "Array<TimeSlot>",
            "format": ""
        },
        {
            "name": "referenceNumber",
            "baseName": "referenceNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "bookingTerminalStatus",
            "baseName": "bookingTerminalStatus",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GetStatusResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

