/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { WebappUser } from '../models/WebappUser';
import { HttpFile } from '../http/http';

export class IspsTruckerChangeTO {
    'id'?: number;
    'firstName'?: string;
    'lastName'?: string;
    'documentStatus'?: IspsTruckerChangeTODocumentStatusEnum;
    'terrorStatus'?: IspsTruckerChangeTOTerrorStatusEnum;
    'state'?: IspsTruckerChangeTOStateEnum;
    'creator'?: WebappUser;
    'supervisor'?: WebappUser;
    'createdDate'?: Date;
    'reset'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "documentStatus",
            "baseName": "documentStatus",
            "type": "IspsTruckerChangeTODocumentStatusEnum",
            "format": ""
        },
        {
            "name": "terrorStatus",
            "baseName": "terrorStatus",
            "type": "IspsTruckerChangeTOTerrorStatusEnum",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "IspsTruckerChangeTOStateEnum",
            "format": ""
        },
        {
            "name": "creator",
            "baseName": "creator",
            "type": "WebappUser",
            "format": ""
        },
        {
            "name": "supervisor",
            "baseName": "supervisor",
            "type": "WebappUser",
            "format": ""
        },
        {
            "name": "createdDate",
            "baseName": "createdDate",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "reset",
            "baseName": "reset",
            "type": "boolean | null",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return IspsTruckerChangeTO.attributeTypeMap;
    }

    public constructor() {
    }
}


export type IspsTruckerChangeTODocumentStatusEnum = "ACCEPTED" | "PENDING" | "DENIED" | "WARN" | "DENIED_PRELIMINARY" | "MANUALLY_APPROVED" | "MANUALLY_DENIED" | "FAILED" ;
export type IspsTruckerChangeTOTerrorStatusEnum = "OK" | "WARNING" | "RED_ALERT" | "MANUALLY_APPROVED" | "MANUALLY_DENIED" ;
export type IspsTruckerChangeTOStateEnum = "APPROVED" | "DENIED" | "PENDING" | "CANCELLED" | "SYSTEM" ;

