/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { LocalTime } from '../models/LocalTime';
import { HttpFile } from '../http/http';

/**
* Terminal opening hour
*/
export class TerminalOpeningHour {
    'id': number;
    'openedFrom': LocalTime;
    'closedFrom': LocalTime;
    'openingHourDay': TerminalOpeningHourOpeningHourDayEnum;
    /**
    * type of Facility opening hour
    */
    'openingHourType': TerminalOpeningHourOpeningHourTypeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "openedFrom",
            "baseName": "openedFrom",
            "type": "LocalTime",
            "format": ""
        },
        {
            "name": "closedFrom",
            "baseName": "closedFrom",
            "type": "LocalTime",
            "format": ""
        },
        {
            "name": "openingHourDay",
            "baseName": "openingHourDay",
            "type": "TerminalOpeningHourOpeningHourDayEnum",
            "format": ""
        },
        {
            "name": "openingHourType",
            "baseName": "openingHourType",
            "type": "TerminalOpeningHourOpeningHourTypeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TerminalOpeningHour.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TerminalOpeningHourOpeningHourDayEnum = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY" ;
export type TerminalOpeningHourOpeningHourTypeEnum = "pickup" | "delivery" | "counter" ;

