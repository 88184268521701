<template>
  <div class="flex justify-center self-center">
    <div class="p-12 bg-white mx-auto rounded-2xl w-100">
      <div class="mb-4">
        <h3 class="font-semibold text-2xl text-gray-800">Sign In</h3>
      </div>
      <div class="space-y-5">
        <div class="space-y-2">
          <label class="text-sm font-medium text-slate-700 tracking-wide"
            >Email</label
          >
          <input
            v-model="email"
            class="input w-full max-w-xs text-neutral-content bg-black"
            type=""
            placeholder="Enter your email"
          />
        </div>
        <div class="space-y-2">
          <label class="mb-5 text-sm font-medium text-gray-700 tracking-wide">
            Password
          </label>
          <input
            v-model="password"
            class="input w-full max-w-xs text-neutral-content bg-black"
            type="password"
            placeholder="Enter your password"
          />
        </div>
        <div class="flex items-center justify-between"></div>
        <div>
          <button
            type="submit"
            @click="$emit('signIn', userPayload)"
            class="w-full flex justify-center bg-[#0b295b] text-gray-100 p-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer"
          >
            Sign in
          </button>
        </div>

        <transition
          enter-from-class="translate-x-[150%] opacity-0"
          enter-active-class="transition duration-300"
        >
          <div
            v-if="props.errorMessage?.length !== 0"
            role="alert"
            class="alert alert-error text-base"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{{ errorMessage }}</span>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, computed } from "vue";

const email = ref("");
const password = ref("");

const userPayload = computed(() => {
  return { email: email.value, password: password.value };
});

const props = defineProps({
  errorMessage: String,
});

const emit = defineEmits<{
  signIn: [payload: { email: string; password: string }]; // named tuple syntax
}>();
</script>
