<template>
  <div class="overflow-x-auto">
    <table class="table">
      <!-- head -->
      <thead>
        <tr>
          <th>Change ID</th>
          <th>Terror status</th>
          <th>Document status</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Creator</th>
          <th>Change state</th>
          <th>Reset</th>
        </tr>
      </thead>
      <tbody>
        <!-- row 1 -->
        <tr
          v-for="change in getOnlyRelevantChanges(props.users!)"
          :key="change.id"
        >
          <td>
            {{ change.id }}
          </td>
          <td>
            {{ change.terrorStatus }}
          </td>
          <td>
            {{ change.documentStatus }}
          </td>
          <td>
            {{ change.firstName }}
          </td>
          <td>{{ change.lastName }}</td>

          <td>
            {{ getCreatorName(change) }}
          </td>

          <td>{{ change.state }}</td>
          <td>{{ change.reset }}</td>

          <th>
            <div
              v-if="
                isAdmin(userStore.getUser()?.roles ?? []) &&
                change.state === 'PENDING' &&
                change.creator?.id != user?.id
              "
              class="flex flex-row space-x-2"
            >
              <!-- TODO: this is not safe, just to maek a point -->
              <div @click="$emit('approve', { approve: true, id: change.id! })">
                <button class="btn btn-success btn-sm">Approve</button>
              </div>

              <div
                @click="$emit('approve', { approve: false, id: change.id! })"
              >
                <button class="btn btn-error btn-sm">Deny</button>
              </div>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits } from "vue";

import { IspsTruckerChangeTO } from "@/services/api-client/client";
import { isAdmin } from "@/util/role-util";
import { useUserStore } from "@/observables/store/user-store";

const userStore = useUserStore();
const user = userStore.getUser();

const emit = defineEmits<{
  approve: [payload: { approve: boolean; id: number }];
}>();

const getOnlyRelevantChanges = (changes: IspsTruckerChangeTO[]) => {
  //TODO: backend fix, they are showing in oldest order on top
  return changes.filter(
    (e) =>
      e.state === "PENDING" || e.state === "SYSTEM" || e.state === "APPROVED"
  );
};

const getCreatorName = (change: IspsTruckerChangeTO) => {
  if (change.state === "SYSTEM") {
    return "SYSTEM";
  }

  return `${change.creator?.firstName} ${change.creator?.lastName}`;
};

const props = defineProps<{ users?: IspsTruckerChangeTO[] }>();
</script>
