/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetStatusRequest } from '../models/GetStatusRequest';
import { GetStatusResponse } from '../models/GetStatusResponse';
import { HttpFile } from '../http/http';

export class GetStatusRequestResponseTO {
    'getStatusRequest'?: GetStatusRequest;
    'getStatusResponse'?: GetStatusResponse;
    'errorCode'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "getStatusRequest",
            "baseName": "getStatusRequest",
            "type": "GetStatusRequest",
            "format": ""
        },
        {
            "name": "getStatusResponse",
            "baseName": "getStatusResponse",
            "type": "GetStatusResponse",
            "format": ""
        },
        {
            "name": "errorCode",
            "baseName": "errorCode",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GetStatusRequestResponseTO.attributeTypeMap;
    }

    public constructor() {
    }
}

