/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * OpenAPI spec version: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DeliveryTimes } from '../models/DeliveryTimes';
import { PickupTimes } from '../models/PickupTimes';
import { TrainLocationTO } from '../models/TrainLocationTO';
import { HttpFile } from '../http/http';

/**
* Train
*/
export class Train {
    'id'?: number;
    'trainNumber': string;
    'trainId'?: string;
    'trainOperator'?: string;
    'trainDate'?: string;
    'pickupTimes'?: PickupTimes;
    'deliveryTimes'?: DeliveryTimes;
    'origin'?: TrainLocationTO;
    'destination'?: TrainLocationTO;
    /**
    * type if train update is for incoming or outgoing train
    */
    'trainType'?: TrainTrainTypeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "trainNumber",
            "baseName": "trainNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainId",
            "baseName": "trainId",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainOperator",
            "baseName": "trainOperator",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainDate",
            "baseName": "trainDate",
            "type": "string",
            "format": "date"
        },
        {
            "name": "pickupTimes",
            "baseName": "pickupTimes",
            "type": "PickupTimes",
            "format": ""
        },
        {
            "name": "deliveryTimes",
            "baseName": "deliveryTimes",
            "type": "DeliveryTimes",
            "format": ""
        },
        {
            "name": "origin",
            "baseName": "origin",
            "type": "TrainLocationTO",
            "format": ""
        },
        {
            "name": "destination",
            "baseName": "destination",
            "type": "TrainLocationTO",
            "format": ""
        },
        {
            "name": "trainType",
            "baseName": "trainType",
            "type": "TrainTrainTypeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Train.attributeTypeMap;
    }

    public constructor() {
    }
}


export type TrainTrainTypeEnum = "import" | "export" ;

