// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { ErrorResponse } from '../models/ErrorResponse';
import { TruckerBlocklistRequestTO } from '../models/TruckerBlocklistRequestTO';
import { TruckerBlocklistResponseTO } from '../models/TruckerBlocklistResponseTO';

/**
 * no description
 */
export class IspsTerminalOperatorBlocklistApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Creates blocklist entry for isps terminal
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested facility
     * @param truckerBlocklistRequestTO 
     */
    public async createBlockListEntryForIspsTerminal(userId: string, facilityId: number, truckerBlocklistRequestTO: TruckerBlocklistRequestTO, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "createBlockListEntryForIspsTerminal", "userId");
        }


        // verify required parameter 'facilityId' is not null or undefined
        if (facilityId === null || facilityId === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "createBlockListEntryForIspsTerminal", "facilityId");
        }


        // verify required parameter 'truckerBlocklistRequestTO' is not null or undefined
        if (truckerBlocklistRequestTO === null || truckerBlocklistRequestTO === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "createBlockListEntryForIspsTerminal", "truckerBlocklistRequestTO");
        }


        // Path Params
        const localVarPath = '/web/ispsfacilities/v1/users/{userId}/facilities/{facilityId}/blocks'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
            .replace('{' + 'facilityId' + '}', encodeURIComponent(String(facilityId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(truckerBlocklistRequestTO, "TruckerBlocklistRequestTO", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Deletes  blocklist entry for isps terminal
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested facility
     * @param blocklistId id of blocklist entry
     */
    public async deleteBlockListEntryForIspsTerminal(userId: string, facilityId: number, blocklistId: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "deleteBlockListEntryForIspsTerminal", "userId");
        }


        // verify required parameter 'facilityId' is not null or undefined
        if (facilityId === null || facilityId === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "deleteBlockListEntryForIspsTerminal", "facilityId");
        }


        // verify required parameter 'blocklistId' is not null or undefined
        if (blocklistId === null || blocklistId === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "deleteBlockListEntryForIspsTerminal", "blocklistId");
        }


        // Path Params
        const localVarPath = '/web/ispsfacilities/v1/users/{userId}/facilities/{facilityId}/blocks/{blocklistId}'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
            .replace('{' + 'facilityId' + '}', encodeURIComponent(String(facilityId)))
            .replace('{' + 'blocklistId' + '}', encodeURIComponent(String(blocklistId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Gets all blocklist entries of a facility
     * @param userId UUID that comes from firebase authentication
     * @param facilityId id of requested terminal
     */
    public async getBlocklistEntiresForFacility(userId: string, facilityId: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'userId' is not null or undefined
        if (userId === null || userId === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "getBlocklistEntiresForFacility", "userId");
        }


        // verify required parameter 'facilityId' is not null or undefined
        if (facilityId === null || facilityId === undefined) {
            throw new RequiredError("IspsTerminalOperatorBlocklistApi", "getBlocklistEntiresForFacility", "facilityId");
        }


        // Path Params
        const localVarPath = '/web/ispsfacilities/v1/users/{userId}/facilities/{facilityId}/blocks'
            .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
            .replace('{' + 'facilityId' + '}', encodeURIComponent(String(facilityId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class IspsTerminalOperatorBlocklistApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to createBlockListEntryForIspsTerminal
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async createBlockListEntryForIspsTerminal(response: ResponseContext): Promise<TruckerBlocklistResponseTO > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("201", response.httpStatusCode)) {
            const body: TruckerBlocklistResponseTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TruckerBlocklistResponseTO", ""
            ) as TruckerBlocklistResponseTO;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: TruckerBlocklistResponseTO = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TruckerBlocklistResponseTO", ""
            ) as TruckerBlocklistResponseTO;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to deleteBlockListEntryForIspsTerminal
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async deleteBlockListEntryForIspsTerminal(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getBlocklistEntiresForFacility
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async getBlocklistEntiresForFacility(response: ResponseContext): Promise<Array<TruckerBlocklistResponseTO> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Not Found", body, response.headers);
        }
        if (isCodeInRange("401", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Unauthorized", body, response.headers);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Bad Request", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: ErrorResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ErrorResponse", ""
            ) as ErrorResponse;
            throw new ApiException<ErrorResponse>(response.httpStatusCode, "Internal Server Error", body, response.headers);
        }
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<TruckerBlocklistResponseTO> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TruckerBlocklistResponseTO>", ""
            ) as Array<TruckerBlocklistResponseTO>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<TruckerBlocklistResponseTO> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TruckerBlocklistResponseTO>", ""
            ) as Array<TruckerBlocklistResponseTO>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
